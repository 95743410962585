import React, { Component } from "react";
import validator from "validator";
import moment from "moment";
import {
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  withMobileDialog,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import { cpf, cnpj } from "cpf-cnpj-validator";

import { uploadObjectToS3 } from "../lib/aws";
import withSnackbar from "./hocs/withSnackbar";
import withTranslator from "./hocs/withTranslator";

import {
  safelyGetSessionStorageItem,
  safelySetSessionStorageItem,
} from "../lib/browser";

const createBrConsumerUnitMutation = loader(
  "../mutations/CreateBrConsumerUnit.graphql"
);
const parseUtilityBillMutation = loader(
  "../mutations/ParseUtilityBill.graphql"
);
const createBrUtilityBillMutation = loader(
  "../mutations/CreateBrUtilityBill.graphql"
);
const logToSlackMutation = loader("../mutations/LogToSlack.graphql");
const dashboardQuery = loader("../queries/Dashboard.graphql");
const currentUserQuery = loader("../queries/CurrentUser.graphql");

const styles = (theme) => ({});

const formLabelColor = "rgba(0, 0, 0, 0.54)";
const inputLabelTextVariant = "body2";
const clientAllowedFormats = "application/pdf";

class ConsumerUnitCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      billUploading: false,
      uploadedBillAwsObjectKey: null,

      // inputs:
      brTariffClass: null,
      utilityCompany: null,
      installationCode: null,
      utilityCustomerCode: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      district: null,
      postalCode: null,
      janConsumption: null,
      febConsumption: null,
      marConsumption: null,
      aprConsumption: null,
      mayConsumption: null,
      junConsumption: null,
      julConsumption: null,
      augConsumption: null,
      sepConsumption: null,
      octConsumption: null,
      novConsumption: null,
      decConsumption: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  handleSubmit() {
    const {
      utilityCompany,
      installationCode,
      utilityCustomerCode,
      address1,
      address2,
      brTariffClass,
      city,
      state,
      district,
      postalCode,
      janConsumption,
      febConsumption,
      marConsumption,
      aprConsumption,
      mayConsumption,
      junConsumption,
      julConsumption,
      augConsumption,
      sepConsumption,
      octConsumption,
      novConsumption,
      decConsumption,
      uploadedBillAwsObjectKey,
    } = this.state;
    const {
      createBrConsumerUnit,
      createBrUtilityBill,
      snackbar,
      brCustomerId,
      logToSlack,
      i18n,
    } = this.props;

    let utilityCompanyId;
    switch (utilityCompany.toLowerCase()) {
      case "cemig":
        utilityCompanyId = 1;
        break;
      case "light":
        utilityCompanyId = 2;
        break;
      default:
        utilityCompanyId = null;
        break;
    }
    let brTariffClassId;
    switch (brTariffClass.toLowerCase()) {
      case "b1":
        brTariffClassId = 1;
        break;
      case "b2":
        brTariffClassId = 2;
        break;
      case "b3":
        brTariffClassId = 3;
        break;
      default:
        brTariffClassId = null;
        break;
    }
    const input = {
      draftTermsOfAdhesionOnSuccess: true,
      brCustomerId,
      brTariffClassId,
      utilityCompanyId,
      installationCode,
      utilityCustomerCode,
      address1,
      address2,
      city,
      state,
      district,
      postalCode,
      janConsumption,
      febConsumption,
      marConsumption,
      aprConsumption,
      mayConsumption,
      junConsumption,
      julConsumption,
      augConsumption,
      sepConsumption,
      octConsumption,
      novConsumption,
      decConsumption,
    };

    this.setState({ loading: true });
    createBrConsumerUnit({
      variables: { input },
      refetchQueries: [
        {
          query: dashboardQuery,
        },
        {
          query: currentUserQuery,
        },
      ],
    }).then(
      (res) => {
        const utilityBillInput = {
          brConsumerUnitId: res.data.createBrConsumerUnit.id,
          awsObjectKey: uploadedBillAwsObjectKey,
        };
        createBrUtilityBill({
          variables: {
            input: utilityBillInput,
          },
        }).then(
          () => {
            this.setState({ loading: false });
            snackbar.setState({
              snackbarMessage: `Informações enviadas. Entraremos em contato em breve.`,
              snackbarOpen: true,
              snackbarVariant: "success",
            });
            this.handleClose();
          },
          (err) => {
            logToSlack({
              variables: {
                input: {
                  title:
                    "Error creating brUtilityBill record in ConsumerUnitCreateForm (energea.com.br)",
                  type: "platform-error",
                  data: [
                    {
                      label: "Input",
                      value: JSON.stringify(utilityBillInput),
                    },
                    {
                      label: "Error",
                      value: JSON.stringify(err?.message || err),
                    },
                  ],
                },
              },
            });
            setLoading(false);
            this.handleClose();
          }
        );
      },
      (err) => {
        logToSlack({
          variables: {
            input: {
              title:
                "Error creating brConsumerUnit record in ConsumerUnitCreateForm (energea.com.br)",
              type: "platform-error",
              data: [
                {
                  label: "Input",
                  value: JSON.stringify(input),
                },
                {
                  label: "Error",
                  value: JSON.stringify(err?.message || err),
                },
              ],
            },
          },
        });
        snackbar.setState({
          snackbarMessage: i18n.t(
            "errorSubmittingInformationPleaseTryAgainLaterOrContactUsAtContatoEnergeaCom",
            "Error submitting information. Please try again later or contact us at contato@energea.com"
          ),
          snackbarOpen: true,
          snackbarVariant: "error",
        });
        this.setState({ loading: false });
      }
    );
  }

  handleScrapeDataFromBill() {
    const { parseUtilityBill, logToSlack } = this.props;
    const { uploadedBillAwsObjectKey, utilityCompany, activeStep } = this.state;
    this.setState({ loading: true });
    const input = { awsObjectKey: uploadedBillAwsObjectKey, utilityCompany };
    parseUtilityBill({
      variables: {
        input,
      },
    }).then(
      (res) => {
        if (res?.data?.parseUtilityBill) {
          this.setState({
            installationCode: res.data.parseUtilityBill.installationCode,
            utilityCustomerCode: res.data.parseUtilityBill.utilityCustomerCode,
            address1: res.data.parseUtilityBill.address1,
            address2: res.data.parseUtilityBill.address2,
            city: res.data.parseUtilityBill.city,
            state: res.data.parseUtilityBill.state,
            district: res.data.parseUtilityBill.district,
            postalCode: res.data.parseUtilityBill.postalCode,
            janConsumption: res.data.parseUtilityBill.janConsumption,
            febConsumption: res.data.parseUtilityBill.febConsumption,
            marConsumption: res.data.parseUtilityBill.marConsumption,
            aprConsumption: res.data.parseUtilityBill.aprConsumption,
            mayConsumption: res.data.parseUtilityBill.mayConsumption,
            junConsumption: res.data.parseUtilityBill.junConsumption,
            julConsumption: res.data.parseUtilityBill.julConsumption,
            augConsumption: res.data.parseUtilityBill.augConsumption,
            sepConsumption: res.data.parseUtilityBill.sepConsumption,
            octConsumption: res.data.parseUtilityBill.octConsumption,
            novConsumption: res.data.parseUtilityBill.novConsumption,
            decConsumption: res.data.parseUtilityBill.decConsumption,
          });
        }
        this.setState({ loading: false, activeStep: activeStep + 1 });
      },
      (err) => {
        logToSlack({
          variables: {
            input: {
              title:
                "Error parsing data utility bill in ConsumerUnitCreateForm (energea.com.br)",
              type: "platform-error",
              data: [
                {
                  label: "Input",
                  value: JSON.stringify(input),
                },
                {
                  label: "Error",
                  value: JSON.stringify(err?.message || err),
                },
              ],
            },
          },
        });
        this.setState({ loading: false });
      }
    );
  }

  handleClose() {
    const { onClose } = this.props;
    this.setState({
      activeStep: 0,
      billUploading: false,
      uploadedBillAwsObjectKey: null,
      utilityCompany: null,
      installationCode: null,
      brTariffClass: null,
      utilityCustomerCode: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      district: null,
      postalCode: null,
      janConsumption: null,
      febConsumption: null,
      marConsumption: null,
      aprConsumption: null,
      mayConsumption: null,
      junConsumption: null,
      julConsumption: null,
      augConsumption: null,
      sepConsumption: null,
      octConsumption: null,
      novConsumption: null,
      decConsumption: null,
    });
    onClose();
  }

  handleBack() {
    const { activeStep } = this.state;
    switch (activeStep) {
      case 0:
        break;
      case 1:
      default:
        this.setState({ activeStep: activeStep - 1 });
        break;
    }
  }

  handleNext() {
    const { activeStep, type } = this.state;
    switch (activeStep) {
      case 0:
        this.setState({ activeStep: activeStep + 1 });
        break;
      case 1:
        this.handleScrapeDataFromBill();
        break;
      case 2:
        this.setState({ activeStep: activeStep + 1 });
        break;
      case 3:
        this.handleSubmit();
        break;
      default:
        break;
    }
  }

  renderStepper() {
    const { activeStep } = this.state;
    switch (activeStep) {
      case 0:
        return this.renderStep0();
      case 1:
        return this.renderStep1();
      case 2:
        return this.renderStep2();
      case 3:
        return this.renderStep3();
      default:
        return null;
    }
  }

  renderActionButtons() {
    const { i18n } = this.props;
    const { activeStep, loading, billUploading } = this.state;
    return (
      <Grid
        item
        container
        justifyContent="flex-end"
        style={{ marginTop: "1rem" }}
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={() => {
              this.handleBack();
            }}
            disabled={loading || billUploading || activeStep === 0}
          >
            {i18n.t("back", "Back")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              this.handleNext();
            }}
            disabled={this.getContinueDisabled() || loading}
          >
            {loading ? (
              <CircularProgress
                style={{ position: "absolute", color: "white" }}
              />
            ) : null}{" "}
            {i18n.t("continue", "Continue")}
          </Button>
        </Grid>
      </Grid>
    );
  }

  renderDistribuidoraInput() {
    const { i18n } = this.props;
    const { utilityCompany } = this.state;
    return (
      <Grid item>
        <FormControl required fullWidth error={utilityCompany === "Other"}>
          <Typography
            style={{ color: formLabelColor }}
            gutterBottom
            variant={inputLabelTextVariant}
          >
            {i18n.t("distributor", "Distributor")}
          </Typography>
          <Select
            fullWidth
            variant="outlined"
            id="distribuidora"
            name="distribuidora"
            required
            value={utilityCompany || "placeholder"}
            onChange={(event) =>
              this.setState({
                utilityCompany: event.target.value,
              })
            }
            renderValue={(val) => {
              if (val === "placeholder")
                return (
                  <Typography style={{ color: formLabelColor }}>
                    {i18n.t("chooseDistributor", "Choose a distributor")}
                  </Typography>
                );
              return <Typography>{val}</Typography>;
            }}
          >
            <MenuItem
              key={`distribuidora-selector-light`}
              name="distribuidora-light"
              value="Light"
            >
              Light
            </MenuItem>
            <MenuItem
              key={`distribuidora-selector-cemig`}
              name="distribuidora-cemig"
              value="CEMIG"
            >
              CEMIG
            </MenuItem>
            <MenuItem
              key={`distribuidora-selector-other`}
              name="distribuidora-other"
              value="Other"
            >
              Outro
            </MenuItem>
          </Select>
          <FormHelperText>
            {utilityCompany === "Other" &&
              i18n.t(
                "weCanOnlyAcceptCustomersFromTheUtilityCompaniesListedAbove",
                "We can only accept customers from the utility companies listed above."
              )}
          </FormHelperText>
        </FormControl>
      </Grid>
    );
  }

  renderRgRneInput() {
    const { rgRne } = this.state;
    return (
      <Grid item>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o RG/RNE"
            fullWidth
            value={rgRne || ""}
            onChange={(event) =>
              this.setState({
                rgRne: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderOrgaoExpedidorInput() {
    const { orgaoExpedidor } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Órgão expedidor
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o órgão expedidor do documento"
            fullWidth
            value={orgaoExpedidor || ""}
            onChange={(event) =>
              this.setState({
                orgaoExpedidor: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderDOBInput() {
    const { dob } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Data de nascimento
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite a data de nascimento"
            fullWidth
            type="date"
            value={dob || ""}
            onChange={(event) =>
              this.setState({
                dob: event.target.value,
              })
            }
            InputLabelProps={{ shrink: true }}
            error={dob && !this.validate("dob", dob)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderNationalityInput() {
    const { nationality } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Nacionalidade
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite a nacionalidade"
            fullWidth
            value={nationality || ""}
            onChange={(event) =>
              this.setState({
                nationality: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderCivilStatusInput() {
    const { civilStatus } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Estado civil
        </Typography> */}
        <FormControl required fullWidth>
          <Select
            fullWidth
            variant="outlined"
            id="selecione-o-estado-civil"
            name="selecione-o-estado-civil"
            required
            // label="Test"
            // labelWidth={50}
            value={civilStatus || "placeholder"}
            onChange={(event) =>
              this.setState({
                civilStatus: event.target.value,
              })
            }
            renderValue={(val) => {
              if (val === "placeholder")
                return (
                  <Typography style={{ color: formLabelColor }}>
                    Selecione o estado civil *
                  </Typography>
                );
              if (val === `casado`) return <Typography>Casado (a)</Typography>;
              if (val === `solteiro`)
                return <Typography>Solteiro (a)</Typography>;
              if (val === `viúvo`) return <Typography>Viúvo (a)</Typography>;
              if (val === `divorciado`)
                return <Typography>Divorciado (a)</Typography>;
              return <Typography>{val}</Typography>;
            }}
          >
            <MenuItem
              key="civil-status-casado"
              name="civil-status-casado"
              value="casado"
            >
              Casado (a)
            </MenuItem>
            <MenuItem
              key="civil-status-solteiro"
              name="civil-status-solteiro"
              value="solteiro"
            >
              Solteiro (a)
            </MenuItem>
            <MenuItem
              key="civil-status-viúvo"
              name="civil-status-viúvo"
              value="viúvo"
            >
              Viúvo (a)
            </MenuItem>
            <MenuItem
              key="civil-status-divorciado"
              name="civil-status-divorciado"
              value="divorciado"
            >
              Divorciado (a)
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  }

  renderCPFInput() {
    const { cpf } = this.state;
    const { i18n } = this.props;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          CPF
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label={i18n.t("enterTheCPF", "Enter the CPF")}
            fullWidth
            value={cpf || ""}
            onChange={(event) =>
              this.setState({
                cpf: event.target.value,
              })
            }
            InputProps={{
              inputComponent: CPFFormat,
            }}
            error={cpf && !this.validate("cpf", cpf)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderFirstNameInput() {
    const { i18n } = this.props;
    const { firstName } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Nome próprio
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label={i18n.t("enterYourFirstName", "Enter your first name")}
            fullWidth
            value={firstName || ""}
            onChange={(event) =>
              this.setState({
                firstName: event.target.value,
              })
            }
            error={firstName && !this.validate("firstName", firstName)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderLastNameInput() {
    const { lastName } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Nome apelidos
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o nome apelidos"
            fullWidth
            value={lastName || ""}
            onChange={(event) =>
              this.setState({
                lastName: event.target.value,
              })
            }
            error={lastName && !this.validate("lastName", lastName)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderTelefoneInput() {
    const { telefone } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Telefone (Whatsapp)
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o número do telefone"
            fullWidth
            value={telefone || ""}
            onChange={(event) =>
              this.setState({
                telefone: event.target.value,
              })
            }
            InputProps={{
              inputComponent: PhoneFormat,
            }}
            error={telefone && !this.validate("telefone", telefone)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderEmailInput() {
    const { email } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          E-mail
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o e-mail"
            fullWidth
            value={email || ""}
            onChange={(event) =>
              this.setState({
                email: event.target.value,
              })
            }
            error={email && !this.validate("email", email)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderStep0() {
    const { i18n } = this.props;
    const { utilityCompany } = this.state;

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {i18n.t(
              "whichDistributorDoesYourConsumerUnitHaveAccountWith",
              "Which distributor does your consumer unit have an account with?"
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {this.renderDistribuidoraInput()}
          </Grid>
        </Grid>
        {this.renderActionButtons()}
      </Grid>
    );
  }

  renderStep1() {
    const { snackbar, logToSlack, i18n } = this.props;
    const { billUploading, loading, uploadedBillAwsObjectKey } = this.state;
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {i18n.t(
              "enterAccountHoldersInformation",
              "Enter the account holder's information"
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            <Button
              onClick={() => this.setState({ uploadBillsSelected: true })}
              variant="contained"
              disabled={billUploading || loading || !!uploadedBillAwsObjectKey}
              size="large"
              color="primary"
              component="label" // https://stackoverflow.com/a/54043619
              startIcon={uploadedBillAwsObjectKey ? <CheckCircle /> : undefined}
            >
              {loading || billUploading ? (
                <CircularProgress
                  style={{ position: "absolute", color: "white" }}
                />
              ) : null}{" "}
              {uploadedBillAwsObjectKey
                ? i18n.t("billUploaded", "Bill uploaded")
                : i18n.t("uploadBill", "Upload bill")}
              <input
                type="file"
                hidden
                onChange={async (event) => {
                  const {
                    target: { validity, files },
                  } = event;
                  const file = files[0];
                  if (!validity.valid) {
                    snackbar.setState({
                      errorUploadingBillsMsg: i18n.t(
                        "errorUploadingBillsMsg",
                        "There was an issue uploading your bill(s). Please try again shortly or reach out directly to contato@energea.com."
                      ),
                      snackbarOpen: true,
                      snackbarVariant: "error",
                    });
                    return;
                  }
                  const fileSize = file.size / 1024 / 1024; // in MiB
                  if (fileSize > 10) {
                    snackbar.setState({
                      snackbarMessage: i18n.t(
                        "documentSizeWarningMsg",
                        "Document must be less than 10MB."
                      ),
                      snackbarOpen: true,
                      snackbarVariant: "error",
                    });
                    return;
                  }
                  const timestamp = new moment().valueOf();
                  this.setState({ billUploading: true });
                  const awsObjectKey = `UtilityBills/UtilityBill_${timestamp}-${file.name}`;
                  const input = {
                    file,
                    key: awsObjectKey,
                    bucket: process.env.REACT_APP_S3_BUCKET,
                  };
                  await uploadObjectToS3(input).then(
                    () => {
                      this.setState({
                        uploadedBillAwsObjectKey: awsObjectKey,
                        billUploading: false,
                      });
                    },
                    (err) => {
                      logToSlack({
                        variables: {
                          input: {
                            title:
                              "Error uploading utility bill to S3 in ConsumerUnitCreateForm (energea.com.br)",
                            type: "platform-error",
                            data: [
                              { label: "Input", value: JSON.stringify(input) },
                              {
                                label: "Error",
                                value: JSON.stringify(err?.message || err),
                              },
                            ],
                          },
                        },
                      });
                      snackbar.setState({
                        snackbarMessage: i18n.t(
                          "errorSendingDocumentMsg",
                          "Error sending the document. Please try again later or send the document directly to contato@energea.com."
                        ),
                        snackbarOpen: true,
                        snackbarVariant: "error",
                      });
                      this.setState({
                        uploadedBillAwsObjectKey: null,
                        billUploading: false,
                      });
                      console.error("Error sending document", err);
                    }
                  );
                }}
                accept={clientAllowedFormats}
              />
            </Button>
          </Grid>
        </Grid>
        {this.renderActionButtons()}
      </Grid>
    );
  }

  renderStep2() {
    const { i18n } = this.props;
    const { type, brTariffClass } = this.state;
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {i18n.t(
              "completeOrReviewTheInformationBelow",
              "Complete or review the information below."
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {[
              {
                label: i18n.t("customerNumber", "Customer number"),
                required: true,
                stateAttr: "utilityCustomerCode",
              },
              {
                label: i18n.t("installationNumber", "Installation number"),
                required: true,
                stateAttr: "installationCode",
              },
              {
                label: i18n.t("tariffClass", "Tariff class"),
                required: true,
                stateAttr: "brTariffClass",
                renderInput: () => (
                  <Select
                    fullWidth
                    variant="outlined"
                    id="brTariffClass"
                    name="brTariffClass"
                    required
                    value={brTariffClass || "placeholder"}
                    onChange={(event) =>
                      this.setState({
                        brTariffClass: event.target.value,
                      })
                    }
                    renderValue={(val) => {
                      if (val === "placeholder")
                        return (
                          <Typography style={{ color: formLabelColor }}>
                            {i18n.t("tariffClass", "Tariff class")} *
                          </Typography>
                        );
                      return <Typography>{val}</Typography>;
                    }}
                  >
                    <MenuItem
                      key={`tariff-class-selector-b1`}
                      name="tariff-class-b1"
                      value="B1"
                    >
                      B1
                    </MenuItem>
                    <MenuItem
                      key={`tariff-class-selector-b2`}
                      name="tariff-class-b2"
                      value="B2"
                    >
                      B2
                    </MenuItem>
                    <MenuItem
                      key={`tariff-class-selector-b3`}
                      name="tariff-class-b3"
                      value="B3"
                    >
                      B3
                    </MenuItem>
                  </Select>
                ),
              },
              {
                label: i18n.t("address1", "Address 1"),
                required: true,
                stateAttr: "address1",
              },
              {
                label: i18n.t("address2", "Address 2"),
                required: false,
                stateAttr: "address2",
              },
              {
                label: i18n.t("district", "District"),
                required: true,
                stateAttr: "district",
              },
              {
                label: i18n.t("city", "City"),
                required: true,
                stateAttr: "city",
              },
              {
                label: i18n.t("state", "State"),
                required: true,
                stateAttr: "state",
              },
              {
                label: i18n.t("postalCode", "Postal code"),
                required: true,
                stateAttr: "postalCode",
              },
            ].map((inputConfig) => {
              const value = this.state[inputConfig.stateAttr];
              return (
                <Grid
                  item
                  key={`cu-create-form-field-${inputConfig.stateAttr}`}
                >
                  <FormControl required={!!inputConfig.required} fullWidth>
                    {inputConfig.renderInput ? (
                      inputConfig.renderInput()
                    ) : (
                      <TextField
                        variant="outlined"
                        required={!!inputConfig.required}
                        label={inputConfig.label}
                        fullWidth
                        value={value || ""}
                        onChange={(event) => {
                          const stateObj = {};
                          stateObj[inputConfig.stateAttr] = event.target.value;
                          this.setState(stateObj);
                        }}
                      />
                    )}
                  </FormControl>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {this.renderActionButtons()}
      </Grid>
    );
  }

  renderStep3() {
    const { i18n } = this.props;
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {i18n.t(
              "completeOrReviewTheInformationBelow",
              "Complete or review the information below."
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {[
              {
                label: i18n.t("janConsumptionKWh", "January consumption (kWh)"),
                required: false,
                stateAttr: "janConsumption",
              },
              {
                label: i18n.t(
                  "febConsumptionKWh",
                  "February consumption (kWh)"
                ),
                required: false,
                stateAttr: "febConsumption",
              },
              {
                label: i18n.t("marConsumptionKWh", "March consumption (kWh)"),
                required: false,
                stateAttr: "marConsumption",
              },
              {
                label: i18n.t("aprConsumptionKWh", "April consumption (kWh)"),
                required: false,
                stateAttr: "aprConsumption",
              },
              {
                label: i18n.t("mayConsumptionKWh", "May consumption (kWh)"),
                required: false,
                stateAttr: "mayConsumption",
              },
              {
                label: i18n.t("junConsumptionKWh", "June consumption (kWh)"),
                required: false,
                stateAttr: "junConsumption",
              },
              {
                label: i18n.t("julConsumptionKWh", "July consumption (kWh)"),
                required: false,
                stateAttr: "julConsumption",
              },
              {
                label: i18n.t("augConsumptionKWh", "August consumption (kWh)"),
                required: false,
                stateAttr: "augConsumption",
              },
              {
                label: i18n.t(
                  "sepConsumptionKWh",
                  "September consumption (kWh)"
                ),
                required: false,
                stateAttr: "sepConsumption",
              },
              {
                label: i18n.t("octConsumptionKWh", "October consumption (kWh)"),
                required: false,
                stateAttr: "octConsumption",
              },
              {
                label: i18n.t(
                  "novConsumptionKWh",
                  "November consumption (kWh)"
                ),
                required: false,
                stateAttr: "novConsumption",
              },
              {
                label: i18n.t(
                  "decConsumptionKWh",
                  "December consumption (kWh)"
                ),
                required: false,
                stateAttr: "decConsumption",
              },
            ].map((inputConfig) => {
              const value = this.state[inputConfig.stateAttr];
              return (
                <Grid
                  item
                  key={`cu-create-form-field-${inputConfig.stateAttr}`}
                >
                  <FormControl required={!!inputConfig.required} fullWidth>
                    <TextField
                      variant="outlined"
                      required={!!inputConfig.required}
                      label={inputConfig.label}
                      fullWidth
                      type="number"
                      value={value || ""}
                      onChange={(event) => {
                        const stateObj = {};
                        stateObj[inputConfig.stateAttr] = parseFloat(
                          event.target.value
                        );
                        this.setState(stateObj);
                      }}
                    />
                  </FormControl>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {this.renderActionButtons()}
      </Grid>
    );
  }

  renderSynchronizeStep() {
    const { snackbar, parseUtilityBill } = this.props;
    const { billUploading, uploadedBills, loading, utilityCompany } =
      this.state;

    return (
      <Grid direction="column" style={{ width: "100%" }} alignItems="center">
        <Grid
          xs={12}
          item
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
            Carregue a sua fatura de eletricidade mais recente para cada
            instalação
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <Button
                onClick={() => this.setState({ uploadBillsSelected: true })}
                variant="contained"
                disabled={billUploading || loading}
                size="large"
                color="primary"
                component="label" // https://stackoverflow.com/a/54043619
              >
                {loading || billUploading ? (
                  <CircularProgress
                    style={{ position: "absolute", color: "white" }}
                  />
                ) : null}{" "}
                Carregar fatura(s)
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={async (event) => {
                    const {
                      target: { validity, files },
                    } = event;
                    const fileArray = Array.from(files);
                    if (fileArray.length > 10) {
                      snackbar.setState({
                        snackbarMessage:
                          "Você pode selecionar no máximo 10 boletos.",
                        // "You can only upload 10 bills at a time.",
                        snackbarOpen: true,
                        snackbarVariant: "error",
                      });
                      return;
                    }
                    if (!validity.valid) {
                      snackbar.setState({
                        snackbarMessage:
                          "There was an issue uploading your bill(s). Please try again shortly or reach out if the issue persists.",
                        snackbarOpen: true,
                        snackbarVariant: "error",
                      });
                      return;
                    }

                    const uploadAttempts = parseInt(
                      safelyGetSessionStorageItem(
                        "utility-bill-upload-attempts"
                      ) || 0,
                      10
                    );
                    if (uploadAttempts >= 100) {
                      snackbar.setState({
                        snackbarMessage:
                          "You have uploaded too many invalid bills. Email contato@energea.com for assistance.",
                        snackbarOpen: true,
                        snackbarVariant: "error",
                      });
                      return;
                    }
                    safelySetSessionStorageItem(
                      "utility-bill-upload-attempts",
                      uploadAttempts + fileArray.length
                    );

                    for (let index = 0; index < fileArray.length; index++) {
                      const file = files[parseInt(index, 10)];
                      const fileSize = file.size / 1024 / 1024; // in MiB
                      if (fileSize > 10) {
                        snackbar.setState({
                          snackbarMessage:
                            "All documents must be less than 10MB.",
                          snackbarOpen: true,
                          snackbarVariant: "error",
                        });
                        return;
                      }
                    }
                    const timestamp = new Date().getTime();
                    this.setState({ billUploading: true });
                    for (let index = 0; index < fileArray.length; index++) {
                      const file = files[parseInt(index, 10)];
                      const awsObjectKey = `NEW_LEAD_BILLS/${timestamp}-${index}-${file.name}`;
                      await uploadObjectToS3({
                        file,
                        key: awsObjectKey,
                        bucket: process.env.REACT_APP_S3_BUCKET,
                      }).then(
                        () => {
                          return parseUtilityBill({
                            variables: {
                              input: { awsObjectKey, utilityCompany },
                            },
                          }).then(
                            (res) => {
                              if (res?.data?.parseUtilityBill) {
                                const updatedBillList = uploadedBills;
                                updatedBillList.push({
                                  awsObjectKey,
                                  fileName: file.name,
                                });
                                this.setState({
                                  uploadedBills: updatedBillList,
                                });
                              } else {
                                snackbar.setState({
                                  snackbarMessage: `Invalid ${utilityCompany} bill (${file.name}). Please try again later or send the document directly to contato@energea.com.`,
                                  snackbarOpen: true,
                                  snackbarVariant: "error",
                                });
                              }
                            },
                            (err) => {
                              console.error(err);
                              snackbar.setState({
                                snackbarMessage:
                                  "Error validating document. Please try again later or send the document directly to contato@energea.com.",
                                snackbarOpen: true,
                                snackbarVariant: "error",
                              });
                            }
                          );
                        },
                        (err) => {
                          console.error(err);
                          snackbar.setState({
                            snackbarMessage:
                              "Error uploading document. Please try again later or send the document directly to contato@energea.com.",
                            snackbarOpen: true,
                            snackbarVariant: "error",
                          });
                          this.setState({
                            uploadedBills: [],
                            billUploading: false,
                          });
                        }
                      );
                    }
                    this.setState({ billUploading: false });
                  }}
                  accept={clientAllowedFormats}
                />
                {/* <Typography
                  color="inherit"
                  variant="body1"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Upload Bill(s)
                </Typography> */}
              </Button>
            </Grid>
            <Collapse in={uploadedBills.length > 0}>
              <Grid
                item
                container
                direction="column"
                style={{ margin: "1rem 0" }}
              >
                {uploadedBills.map((bill, index) => (
                  <Grid item>
                    <Typography variant="body2">{bill.fileName}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Collapse>
            <Collapse in={uploadedBills.length === 0}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Grid item style={{ margin: "1rem 0" }}>
                  <Typography variant="body2">ou</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                    disabled={!!billUploading}
                  >
                    <Typography
                      color="inherit"
                      variant="body1"
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      // className={classes.typeText}
                    >
                      pule por enquanto
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Collapse>
            {this.renderActionButtons()}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  validate(attr, val) {
    switch (attr) {
      case "firstName":
      case "lastName":
        return val && val.length >= 2;
      case "cpf":
        return cpf.isValid(val);
      case "cnpj":
        return cnpj.isValid(val);
      case "email":
        return validator.isEmail(val);
      case "telefone":
        return validator.isMobilePhone(val, "pt-BR");
      case "dob":
        return moment(val).isValid() && moment(val).isBefore(moment());
      case "companyName":
        return val && val.length >= 2;
      default:
        return true;
    }
  }

  getContinueDisabled() {
    const {
      activeStep,
      utilityCompany,
      brTariffClass,
      uploadedBillAwsObjectKey,
      installationCode,
      utilityCustomerCode,
      address1,
      city,
      state,
      postalCode,
      district,
      janConsumption,
      febConsumption,
      marConsumption,
      aprConsumption,
      mayConsumption,
      junConsumption,
      julConsumption,
      augConsumption,
      sepConsumption,
      octConsumption,
      novConsumption,
      decConsumption,
    } = this.state;
    switch (activeStep) {
      case 0:
        return utilityCompany === null || utilityCompany === "Other";
      case 1:
        return !uploadedBillAwsObjectKey;
      case 2:
        return (
          !installationCode ||
          !utilityCustomerCode ||
          !address1 ||
          !city ||
          !state ||
          !postalCode ||
          !district ||
          !brTariffClass
        );
      case 3:
        return (
          !janConsumption &
          !febConsumption &
          !marConsumption &
          !aprConsumption &
          !mayConsumption &
          !junConsumption &
          !julConsumption &
          !augConsumption &
          !sepConsumption &
          !octConsumption &
          !novConsumption &
          !decConsumption
        );
      default:
        return false;
    }
  }

  render() {
    const { fullScreen, theme } = this.props;
    const { activeStep } = this.state;

    return (
      <Grid
        container
        justifyContent="center"
        style={{ height: "100%", overflow: "scroll" }}
      >
        <Grid item md={9} xs={12} style={{ height: "100%" }}>
          <Grid container justifyContent="center">
            <Grid item>
              <Stepper
                style={{ paddingTop: 0 }}
                alternativeLabel
                activeStep={activeStep}
              >
                {[1, 2, 3, 4].map((step) => (
                  <Step key={String(step)}>
                    <StepLabel />
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid
                item
                container
                xs={12}
                lg={10}
                // spacing={5}
                justifyContent="center"
                // alignItems="center"
              >
                {this.renderStepper()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withSnackbar(
  graphql(createBrConsumerUnitMutation, {
    name: "createBrConsumerUnit",
  })(
    graphql(createBrUtilityBillMutation, {
      name: "createBrUtilityBill",
    })(
      graphql(parseUtilityBillMutation, {
        name: "parseUtilityBill",
      })(
        graphql(logToSlackMutation, { name: "logToSlack" })(
          withStyles(styles, { withTheme: true })(
            withMobileDialog()(withTranslator(ConsumerUnitCreateForm)),
            {
              whiteBackground: true,
            }
          )
        )
      )
    )
  )
);
