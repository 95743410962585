import React, { Suspense } from "react";
import HttpsRedirect from "react-https-redirect";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, CircularProgress, Grid } from "@material-ui/core";
import { ApolloProvider } from "react-apollo";
import TagManager from "react-gtm-module";

import "./index.css";
import App from "./App.jsx";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import "./i18n";
import client from "./apollo";

const tagManagerArgs = {
  gtmId: "GTM-MJQQQT7",
};
// if (process.env.NODE_ENV === "production") {
TagManager.initialize(tagManagerArgs);
// }

ReactDOM.render(
  <Suspense
    fallback={
      <Grid
        container
        style={{ height: "100%", width: "100%", position: "absolute" }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Grid>
    }
  >
    <CssBaseline />
    <HttpsRedirect>
      <MuiThemeProvider theme={theme}>
        <Router>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </Router>
      </MuiThemeProvider>
    </HttpsRedirect>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
