import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import validator from "validator";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import withSnackbar from "./hocs/withSnackbar";
import withTranslator from "./hocs/withTranslator";
import CpfFormat from "./CpfFormat";

const updateBrContactMutation = loader("../mutations/UpdateBrContact.graphql");

const styles = () => ({});
const attrs = ["firstName", "lastName", "cpf"];

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      cpf: "",
      changeObj: {},
      changed: false,
    };
    this.update = this.update.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    const {
      parent: {
        props: {
          data: { me },
        },
      },
    } = this.props;
    if (!me || !me.brContact) return;
    const { state } = this;
    const nextState = {};
    attrs.forEach((attr) => {
      if (me.brContact[String(attr)] !== state[String(attr)]) {
        nextState[String(attr)] = me.brContact[String(attr)];
      }
    });
    this.setState(nextState);
  }

  save() {
    this.setState({ pending: true });
    const {
      snackbar,
      updateBrContact,
      parent: {
        props: {
          data: {
            me: {
              brContact: { id },
            },
          },
          currentUserQuery,
        },
      },
    } = this.props;

    const { changeObj } = this.state;

    updateBrContact({
      variables: { input: { id, ...changeObj } },
      // refetchQueries: [
      //   {
      //     query: currentUserQuery,
      //   },
      //   { query: userSettingsQuery },
      // ],
    }).then(
      () => {
        snackbar.setState({
          snackbarMessage: `Profile successfully edited.`,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        this.setState({ pending: false, changed: false });
      },
      () => {
        snackbar.setState({
          snackbarMessage: `Error editing profile.`,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
        this.setState({ pending: false });
      }
    );
  }

  validate(attr) {
    const { state } = this;
    const val = state[String(attr)];
    switch (attr) {
      case "firstName":
      case "lastName":
      case "email":
        return !!val;
      case "phone":
        return val && validator.isMobilePhone(val, "pt-BR");
      case "cpf":
        return val && validator.isLength(val, { min: 11, max: 11 });
      default:
        return true;
    }
  }

  update(event) {
    const { changeObj } = this.state;
    if (!event) return;
    const { name, value } = event.target;
    if (!name) return;
    const {
      parent: {
        props: {
          data: {
            me: { brContact },
          },
        },
      },
    } = this.props;
    const newState = { changeObj };
    newState[String(name)] = value;
    newState.changeObj[String(name)] = value;
    if (changeObj[String(name)] && brContact[String(name)] === value) {
      delete newState.changeObj[String(name)];
    }
    newState.changed = Object.keys(newState.changeObj).length > 0;

    this.setState(newState);
  }

  saveButtonDisabled() {
    const personalInfoAttrs = [...attrs];
    let disabled = false;
    personalInfoAttrs.forEach((attr) => {
      if (!this.validate(attr)) {
        disabled = true;
      }
    });
    return disabled;
  }

  render() {
    const {
      theme,
      parent: {
        props: {
          data,
          data: { me },
        },
      },
      i18n,
    } = this.props;
    if (!me.brContact || data.loading) return null;
    const { changed, firstName, lastName, pending, cpf } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            // style={{ marginBottom: '1em', borderBottom: 'solid 1px' }}
            gutterBottom
            variant="h5"
          >
            {i18n.t("personalInfo", "Personal Info")}
          </Typography>
          <Divider
            style={{
              height: 0,
              width: "100%",
              margin: "0 0 1.5em 0",
              borderTop: `thin solid ${theme.palette.divider}`,
            }}
          />
        </Grid>
        {/* <Grid item container xs={12} md={4}>
            <ProfileImageForm userId={me.user.id} parent={this} />
          </Grid> */}
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              id="firstName"
              name="firstName"
              label={i18n.t("firstName", "First Name")}
              value={firstName || ""}
              onChange={this.update}
              fullWidth
              error={!this.validate("firstName")}
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              id="lastName"
              name="lastName"
              error={!this.validate("lastName")}
              label={i18n.t("lastName", "Last Name")}
              onChange={this.update}
              value={lastName || ""}
              fullWidth
              autoComplete="family-name"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              required
              name="primaryPhone"
              label="Phone"
              type="tel"
              autoComplete="phone"
              error={!this.validate("primaryPhone")}
              variant="outlined"
              onChange={this.update}
              value={primaryPhone || ""}
              fullWidth
              InputProps={{
                inputComponent: PhoneFormat,
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              disabled={me.brContact.cpf}
              id="cpf"
              variant="outlined"
              name="cpf"
              label="CPF"
              value={cpf || ""}
              onChange={this.update}
              fullWidth
              error={!this.validate("cpf")}
              InputProps={{
                inputComponent: CpfFormat,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              // size="large"
              variant="contained"
              color="primary"
              disabled={!changed || pending || this.saveButtonDisabled()}
              onClick={this.save}
            >
              {pending ? (
                <CircularProgress style={{ height: "26px", width: "26px" }} />
              ) : (
                "Save"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ProfileForm.propTypes = {
  me: PropTypes.object.isRequired,
};

export default withSnackbar(
  graphql(updateBrContactMutation, { name: "updateBrContact" })(
    withStyles(styles, { withTheme: true })(withTranslator(ProfileForm))
  )
);
