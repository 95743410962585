import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
    fallbackLng: process.env.NODE_ENV === "production" ? "pt" : "en",
    whitelist: ["en", "pt"],
    backend: {
      // path where resources get loaded from, or a function
      // returning a path:
      // The function receives the lng, namespace, key as parameters
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    ns: ["translation"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // debug: true, // enable to see logs in console
    lng: process.env.NODE_ENV === "production" ? "pt" : "en", // Hardcode this for dev purposes
  });
export default i18n;
