import React, { Component, Fragment } from 'react';

import StatusSnackbar from '../StatusSnackbar';

const withSnackbar = (WrappedComponent) => {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        snackbarOpen: false,
        snackbarMessage: '',
        snackbarVariant: '',
      };
    }

    render() {
      const { snackbarOpen, snackbarVariant, snackbarMessage } = this.state;
      return (
        <Fragment>
          <WrappedComponent snackbar={this} {...this.props} />
          <StatusSnackbar
            zIndex={14000}
            variant={snackbarVariant}
            message={snackbarMessage}
            open={snackbarOpen}
            parent={this}
          />
        </Fragment>
      );
    }
  };
  return HOC;
};

export default withSnackbar;
