import React, { useState, useEffect } from "react";
import { graphql, Query } from "react-apollo";
import { useOktaAuth } from "@okta/okta-react";
import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useTheme,
  withStyles,
  Divider,
  withMobileDialog,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  // ListItemSecondaryAction,
} from "@material-ui/core";
import numeral from "numeral";

import { Add, Close, Info, SwapHoriz } from "@material-ui/icons";

import { loader } from "graphql.macro";
import { Alert } from "@material-ui/lab";

import PerformanceChart from "./PerformanceChart";
import InvoiceList from "./InvoiceList";
import UserManagementDialog from "./UserManagementDialog";
import ConsumerUnitCreateForm from "./ConsumerUnitCreateForm";
import { formatCurrency } from "../lib/formatting";
import withTranslator from "./hocs/withTranslator";
import PartnerConsumerUnitUploadDialog from "./PartnerConsumerUnitUploadDialog";

const dashboardQuery = loader("../queries/Dashboard.graphql");
const consumerUnitsQuery = loader("../queries/PartnerConsumerUnits.graphql");
const logToSlackMutation = loader("../mutations/LogToSlack.graphql");

const styles = (theme) => ({
  sections: {
    padding: theme.spacing(2),
    borderTop: "5px solid #fff",
  },
  sectionTitleDividers: {
    width: "100%",
    margin: "1rem 0",
  },
});

const Dashboard = (props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const { data, logToSlack, classes } = props;

  useEffect(() => {
    if (authState?.isPending) {
      return;
    }
    if (authState && !authState?.isPending && !authState?.isAuthenticated) {
      oktaAuth.signOut({
        postLogoutRedirectUri: `${window.location.origin}/login`,
      });
    }
    if (authState?.isAuthenticated && !data.loading && !data?.me?.brContact) {
      oktaAuth.signOut({
        postLogoutRedirectUri: `${window.location.origin}/login`,
      });
    }
  }, [authState]); // Dependencies for useEffect

  if (data.loading || data.error) {
    if (data.error) {
      logToSlack({
        variables: {
          input: {
            title: "Error fetching dashboard data (energea.com.br)",
            type: "platform-error",
            data: [
              {
                label: "Error Message",
                value: JSON.stringify(data.error),
              },
            ],
          },
        },
      });
    }
    return (
      <Grid
        container
        component={Paper}
        className={classes.sections}
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "300px" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (!data.me) return null;
  const {
    me: { brContact },
  } = data;
  if (!brContact) {
    logToSlack({
      variables: {
        input: {
          title: "No BrContact returned on dashboard (energea.com.br)",
          type: "platform-error",
          data: [
            {
              label: "Data",
              value: JSON.stringify(data),
            },
          ],
        },
      },
    });
    return null;
  }

  const isSalesPerson = !!brContact.brSalesPerson;
  if (isSalesPerson) {
    return <SalesPersonDashboard {...props} />;
  }
  return <CustomerDashboard {...props} />;
};

const SalesPersonDashboard = (props) => {
  const theme = useTheme();
  const [expansionPanelOpen, setExpansionPanelOpen] = useState(false);
  const { classes, data, i18n } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [consumerUnitCreateDialogOpen, setConsumerUnitCreateDialogOpen] =
    useState(false);
  const sectionSpacing = 4;
  const brContact = data?.me?.brContact;
  const brSalesPerson = brContact?.brSalesPerson;

  const lintedPowerPlans = brSalesPerson.brPowerPlans?.filter(
    (powerPlan) =>
      powerPlan.utilityCompany &&
      powerPlan.brTariffClass &&
      powerPlan.discountRate !== null &&
      powerPlan.upfrontCommissionRate !== null &&
      powerPlan.residualCommissionRate !== null
  );

  return (
    <>
      <Grid
        container
        justifyContent="center"
        // alignItems="center"
        style={{ background: theme.palette.primary.main, padding: "2rem 1rem" }}
      >
        <Grid item xs={12} lg={10}>
          <Grid container spacing={sectionSpacing}>
            <Grid
              container
              component={Paper}
              className={classes.sections}
              style={{ marginBottom: "2rem" }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6">
                    <b>
                      {i18n.t("welcome", "Welcome")}, {brContact.fullName}
                    </b>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => {
                      setExpansionPanelOpen(!expansionPanelOpen);
                    }}
                  >
                    <Info />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Collapse in={expansionPanelOpen} style={{ width: "100%" }}>
                  <Grid container style={{ width: "100%", marginTop: "1rem" }}>
                    <Grid item>
                      <Typography variant="body1">
                        <b>{i18n.t("powerPlans", "Power Plans")}</b>
                      </Typography>
                    </Grid>
                    <Divider className={classes.sectionTitleDividers} />
                    {lintedPowerPlans.length > 0 ? (
                      <TableContainer component={Grid}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <b>
                                  {i18n.t("distributor", "Utility company")}
                                </b>
                              </TableCell>
                              <TableCell>
                                <b>{i18n.t("tariffClass", "Tariff class")}</b>
                              </TableCell>
                              <TableCell align="right">
                                <b>{i18n.t("discountRate", "Discount rate")}</b>
                              </TableCell>
                              <TableCell align="right">
                                <b>
                                  {i18n.t(
                                    "upfrontCommissionRate",
                                    "Upfront commission rate"
                                  )}
                                </b>
                              </TableCell>
                              <TableCell align="right">
                                <b>
                                  {i18n.t(
                                    "residualCommissionRate",
                                    "Residual commission rate"
                                  )}
                                </b>
                              </TableCell>
                              <TableCell align="right">
                                <b>
                                  {i18n.t(
                                    "commissionDuration",
                                    "Commission duration"
                                  )}
                                </b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {lintedPowerPlans.map((powerPlan, index) => {
                              const lastRow =
                                index === lintedPowerPlans.length - 1;
                              return (
                                <TableRow key={powerPlan.id}>
                                  <TableCell
                                    style={{
                                      borderBottom: lastRow ? "none" : null,
                                    }}
                                  >
                                    {powerPlan.utilityCompany.name}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      borderBottom: lastRow ? "none" : null,
                                    }}
                                  >
                                    {powerPlan.brTariffClass.name}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{
                                      borderBottom: lastRow ? "none" : null,
                                    }}
                                  >
                                    {numeral(powerPlan.discountRate).format(
                                      "0[.]00%"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{
                                      borderBottom: lastRow ? "none" : null,
                                    }}
                                  >
                                    {numeral(
                                      powerPlan.upfrontCommissionRate
                                    ).format("0[.]00%")}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{
                                      borderBottom: lastRow ? "none" : null,
                                    }}
                                  >
                                    {numeral(
                                      powerPlan.residualCommissionRate
                                    ).format("0[.]00%")}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{
                                      borderBottom: lastRow ? "none" : null,
                                    }}
                                  >
                                    {powerPlan.commissionDurationMonths
                                      ? `${
                                          powerPlan.commissionDurationMonths
                                        } ${i18n.t("months", "months")}`
                                      : i18n.t("Perpetual", "Perpetual")}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Alert severity="info">
                        {i18n.t("noPowerPlans", "No power plans")}
                      </Alert>
                    )}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            <Grid container component={Paper} className={classes.sections}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="body1">
                    <b>{i18n.t("consumerUnits", "Consumer Units")}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => {
                      setConsumerUnitCreateDialogOpen(true);
                    }}
                  >
                    {i18n.t("addConsumerUnit", "Add Consumer Unit")}
                  </Button>
                  <PartnerConsumerUnitUploadDialog
                    open={consumerUnitCreateDialogOpen}
                    handleClose={() => {
                      setConsumerUnitCreateDialogOpen(false);
                    }}
                    brSalesPersonId={brSalesPerson.id}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.sectionTitleDividers} />
              <TextField
                label={i18n.t("search", "Search")}
                variant="outlined"
                value={searchString || ""}
                fullWidth
                onChange={(e) => {
                  setSearchString(e.target.value);
                  // refetch();
                }}
              />
              <Query
                query={consumerUnitsQuery}
                variables={{
                  filter: {
                    brSalesPerson: { id: brSalesPerson.id },
                    q: searchString,
                  },
                  pagination: { page: page + 1, perPage: rowsPerPage },
                  sort: { field: "name", order: "ASC" },
                }}
              >
                {({ loading, error, data, refetch }) => {
                  if (error || loading)
                    return (
                      <Grid
                        style={{
                          position: "fixed",
                          top: "50%",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <CircularProgress />
                      </Grid>
                    );
                  return (
                    <Grid container style={{ marginTop: "1rem" }}>
                      {data?.brConsumerUnitFeed?.rows.length > 0 ? (
                        <TableContainer component={Grid}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <b>{i18n.t("name", "Name")}</b>
                                </TableCell>
                                <TableCell align="right">
                                  <b>{i18n.t("status", "Status")}</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.brConsumerUnitFeed?.rows?.map(
                                (consumerUnit, index) => {
                                  const lastRow =
                                    index ===
                                    data.brConsumerUnitFeed?.rows?.length - 1;
                                  return (
                                    <TableRow key={consumerUnit.id}>
                                      <TableCell
                                        style={{
                                          borderBottom: lastRow ? "none" : null,
                                        }}
                                      >
                                        {consumerUnit.name}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        style={{
                                          borderBottom: lastRow ? "none" : null,
                                        }}
                                      >
                                        {consumerUnit.brConsumerUnitStage.name}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            count={data.brConsumerUnitFeed?.count || 0}
                            page={page}
                            onPageChange={(event, page) => setPage(page)}
                            onRowsPerPageChange={(event) => {
                              setPage(0);
                              setRowsPerPage(event.target.value);
                            }}
                            labelRowsPerPage={i18n.t(
                              "rowsPerPage",
                              "Rows per page"
                            )}
                            labelDisplayedRows={({ from, to, count }) =>
                              `${from}-${to} ${i18n.t("of", "of")} ${count}`
                            }
                            // classes={
                            //   fullScreen
                            //     ? {
                            //         actions: classes.paginationActions,
                            //         toolbar: classes.paginationToolbar,
                            //         selectRoot: classes.paginationSelectRoot,
                            //       }
                            //     : {}
                            // }
                          />
                        </TableContainer>
                      ) : (
                        <Alert severity="info">
                          {i18n.t("noConsumerUnits", "No consumer units")}
                        </Alert>
                      )}
                    </Grid>
                  );
                }}
              </Query>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const CustomerDashboard = (props) => {
  const theme = useTheme();
  const [selectedConsumerUnit, setSelectedConsumerUnit] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [manageContactsCustomerId, setManageContactsCustomerId] =
    useState(null);
  const [switchAccountOpen, setSwitchAccountOpen] = useState(false);
  const [consumerUnitCreateDialogOpen, setConsumerUnitCreateDialogOpen] =
    useState(null);
  const { classes, data, i18n } = props;
  const sectionSpacing = 4;
  let curConsumerUnitData;
  const brContact = data?.me?.brContact;

  if (selectedConsumerUnit) {
    brContact.brContactsBrCustomers.forEach((customer) => {
      if (selectedCustomer.id === customer.brCustomer.id) {
        customer.brCustomer.brConsumerUnits.forEach((consumerUnit) => {
          if (selectedConsumerUnit === consumerUnit.id) {
            curConsumerUnitData = consumerUnit;
          }
        });
      }
    });
  }

  const renderDashboardMenu = () => {
    if (!selectedCustomer?.id && brContact.brContactsBrCustomers.length > 0) {
      const defaultCustomer =
        brContact.brContactsBrCustomers.filter(
          (x) => x.brCustomer.brConsumerUnits?.length > 0
        )[0] || brContact.brContactsBrCustomers[0];
      setSelectedCustomer(defaultCustomer.brCustomer);
    }
    let curCustomerData;
    if (selectedCustomer) {
      curCustomerData = brContact.brContactsBrCustomers.find(
        (el) => el.brCustomer.id === selectedCustomer.id
      );
      if (!selectedConsumerUnit) {
        if (curCustomerData.brCustomer.brConsumerUnits?.[0]?.id) {
          setSelectedConsumerUnit(
            curCustomerData.brCustomer.brConsumerUnits?.[0]?.id
          );
        }
      }
    }
    if (!curCustomerData) {
      return null;
    }
    if (
      !curCustomerData.brCustomer.brConsumerUnits.length &&
      consumerUnitCreateDialogOpen === null
    ) {
      // NOTE: Once its set to false, don't automatically open
      setConsumerUnitCreateDialogOpen(true);
    }

    const renderConsumerUnitStage = (consumerUnitStage) => {
      if (!consumerUnitStage) {
        return null;
      }
      let backgroundColor = null;
      switch (consumerUnitStage.id) {
        case 1:
          backgroundColor = theme.palette.warning.dark;
          break;
        case 2:
          backgroundColor = theme.palette.secondary.main;
          break;
        case 3:
          backgroundColor = theme.palette.secondary.main;
          break;
        case 4:
          backgroundColor = theme.palette.warning.dark;
          break;
        case 5:
          backgroundColor = theme.palette.error.dark;
          break;
        default:
          return null;
      }
      return (
        <Grid item style={{ marginTop: ".5rem" }}>
          <Chip
            variant="caption"
            style={{
              backgroundColor,
              color: "#fff",
            }}
            label={consumerUnitStage.name}
          />
        </Grid>
      );
    };

    return (
      <Grid
        container
        component={Paper}
        style={{
          padding: 0,
        }}
      >
        <Grid
          item
          xs={12}
          component={Paper}
          elevation={8}
          // outlined
          style={{
            padding: "1rem",
            border: `solid 5px ${theme.palette.secondary.main}`,
          }}
        >
          <Grid item>
            <Typography>
              <b>
                {i18n.t("welcome", "Welcome")}, {brContact.fullName}
              </b>
            </Typography>
          </Grid>
          <Divider className={classes.sectionTitleDividers} />
          {/* curCustomerData */}
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item>
                <Typography gutterBottom variant="body2">
                  <b>{i18n.t("account", "Account")} :</b>{" "}
                  {curCustomerData.brCustomer.name}
                </Typography>
              </Grid>
              {/* <Grid item>
                <Typography gutterBottom variant="body2">
                  <b>{i18n.t("status", "Status")} :</b> Invoices Due
                </Typography>
              </Grid> */}
              <Grid
                container
                justifyContent="space-between"
                style={{ marginTop: "2rem" }}
              >
                {["admin", "owner"].indexOf(curCustomerData.role) > -1 && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() =>
                        setManageContactsCustomerId(
                          curCustomerData.brCustomer.id
                        )
                      }
                      startIcon={<i className="fa-solid fa-users"></i>}
                    >
                      {i18n.t("manageUser", "Manage Users")}
                    </Button>
                  </Grid>
                )}
                {brContact.brContactsBrCustomers.length > 1 && (
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<SwapHoriz />}
                      onClick={() => setSwitchAccountOpen(true)}
                    >
                      {i18n.t("switchAccount", "Switch Accounts")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: "1rem 1.3rem" }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                gutterBottom
                style={{ fontWeight: "bold", marginTop: "1rem" }}
              >
                <i
                  style={{ marginRight: ".5rem" }}
                  className="fa-solid fa-buildings"
                />
                {i18n.t("consumerUnits", "Consumer Units")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  setConsumerUnitCreateDialogOpen(true);
                }}
              >
                <Add />
              </IconButton>
            </Grid>
          </Grid>
          <List>
            {curCustomerData.brCustomer.brConsumerUnits.map(
              (consumerUnit, i) => (
                <ListItem
                  button
                  selected={selectedConsumerUnit === consumerUnit.id}
                  divider={
                    i !== curCustomerData.brCustomer.brConsumerUnits.length - 1
                  }
                  style={{ borderRadius: "1rem" }}
                  onClick={() => setSelectedConsumerUnit(consumerUnit.id)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        <b>{consumerUnit.name}</b>
                      </Typography>
                    }
                    secondary={
                      <Grid container direction="column">
                        <Grid item container>
                          {renderConsumerUnitStage(
                            consumerUnit.brConsumerUnitStage
                          )}
                        </Grid>
                        <Grid
                          item
                          container
                          style={{ paddingLeft: ".25rem", marginTop: ".5rem" }}
                          spacing={1}
                        >
                          <Grid item>
                            <i className="fa-solid fa-location-dot"></i>
                          </Grid>
                          <Grid item>
                            <Typography gutterBottom variant="caption">
                              {consumerUnit.address1}
                              <br />
                              {consumerUnit.address2 && (
                                <>
                                  {consumerUnit.address2}
                                  <br />
                                </>
                              )}
                              {consumerUnit.city}, {consumerUnit.district},{" "}
                              {consumerUnit.postalCode}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              )
            )}
          </List>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => {
                setConsumerUnitCreateDialogOpen(true);
              }}
              endIcon={<Add />}
            >
              {i18n.t("addConsumerUnit", "Add Consumer Unit")}
            </Button>
          </Grid>
        </Grid>

        {/* {brContact.brContactsBrCustomers.map((customer) => {
          return (
            <Grid item xs={12}>
              <Grid container direction="column">
                <Grid item>
                  <Typography>{customer.brCustomer.name}</Typography>
                </Grid>
                <Grid item style={{ textAlign: "right", marginTop: ".5rem" }}>
                  {["admin", "owner"].indexOf(customer.role) && (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setManageContactsCustomerId(customer.brCustomer.id)
                      }
                    >
                    {i18n.t("manageUser", "Manage Users")}
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Divider className={classes.sectionTitleDividers} />
              <Typography>Consumer Units</Typography>
              <List>
                {customer.brCustomer.brConsumerUnits.map((consumerUnit, i) => (
                  <ListItem
                    button
                    selected={selectedConsumerUnit === consumerUnit.id}
                    divider={
                      i !== customer.brCustomer.brConsumerUnits.length - 1
                    }
                    style={{ borderRadius: "1rem" }}
                    onClick={() => setSelectedConsumerUnit(consumerUnit.id)}
                  >
                    <ListItemText
                      primary={consumerUnit.name}
                      secondary={
                        <div style={{ paddingLeft: ".5rem" }}>
                          {consumerUnit.address1}
                          <br />
                          {consumerUnit.address2}
                          <br />
                          {consumerUnit.city}, {consumerUnit.district},{" "}
                          {consumerUnit.postalCode}
                        </div>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          );
        })} */}
      </Grid>
    );
  };

  const renderAccountSummary = () => {
    let jsx;
    if (data.loading) {
      jsx = (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "300px" }}
        >
          <CircularProgress />
        </Grid>
      );
    } else {
      jsx = (
        <Grid
          container
          style={{ minHeight: "300px" }}
          justifyContent="space-between"
          direction="column"
        >
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ color: theme.palette.secondary.main }}
                >
                  <b>{i18n.t("amountDue", "Amount Due")}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ color: theme.palette.secondary.main }}
                >
                  <b>{formatCurrency(curConsumerUnitData?.amountDue || 0)}</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography gutterBottom variant="body2">
                  {i18n.t("pastDue", "Past Due")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="body2">
                  {formatCurrency(curConsumerUnitData?.pastDue || 0)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  {i18n.t("currentDue", "Current Due")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  {formatCurrency(
                    curConsumerUnitData &&
                      curConsumerUnitData.amountDue -
                        curConsumerUnitData.pastDue
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              direction="column"
              style={{ marginTop: "1rem" }}
            >
              <Grid item>
                <Button
                  component="a"
                  href={
                    curConsumerUnitData?.mostRecentUnpaidBrInvoice
                      ?.stripeInvoice?.invoicePaymentUrl
                  }
                  variant="contained"
                  size="large"
                  disabled={
                    !curConsumerUnitData?.mostRecentUnpaidBrInvoice
                      ?.stripeInvoice?.invoicePaymentUrl
                  }
                  color="secondary"
                  style={{ margin: ".5rem" }}
                >
                  {(curConsumerUnitData?.pastDue || 0) > 0
                    ? i18n.t("payPastDueInvoice", "Pay Past Due Invoice")
                    : i18n.t("makeAPayment", "Make a Payment")}
                </Button>
                {/* <StripeBuyButton
                publishableKey={
                  "pk_test_51P3MsgHSL3dpgWvRFWuEG2p23hH2ZtlaLkPKzi5XZsQM2qAfWjsxPFLyBZiXDcMHIyfwJH1qPIwDYYKp06PCmaCP00rTv0jMSZ"
                }
                buyButtonId={"buy_btn_1P3fjgHSL3dpgWvRQtE2gAfH"}
              /> */}
              </Grid>
              <Grid>
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  href={
                    curConsumerUnitData?.mostRecentUnpaidBrInvoice
                      ?.stripeInvoice?.invoiceDownloadUrl
                  }
                  disabled={
                    !curConsumerUnitData?.mostRecentUnpaidBrInvoice
                      ?.stripeInvoice?.invoiceDownloadUrl
                  }
                  style={{ margin: ".5rem" }}
                >
                  {i18n.t(
                    "viewLatestUnpaidInvoice",
                    "View Latest Unpaid Invoice"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container component={Paper} className={classes.sections}>
        <Typography variant="body1">
          <b>{i18n.t("accountSummary", "Account Summary")}</b>
        </Typography>
        <Divider className={classes.sectionTitleDividers} />
        {jsx}
      </Grid>
    );
  };

  const renderInvoices = () => {
    // if (data.loading || true) {
    //   return (
    //     <Skeleton variant="rect" style={{ width: "100%", height: "100%" }} />
    //   );
    //   return <Typography>Loading...</Typography>;
    // }
    return (
      <Grid container component={Paper} className={classes.sections}>
        <Typography variant="body1">
          <b>{i18n.t("invoices", "Invoices")}</b>
        </Typography>
        <Divider className={classes.sectionTitleDividers} />
        {selectedConsumerUnit && (
          <InvoiceList brConsumerUnitId={selectedConsumerUnit} />
        )}
      </Grid>
    );
  };
  const renderPerformance = () => {
    return (
      <Grid container component={Paper} className={classes.sections}>
        <Typography variant="body1">
          <b>{i18n.t("performance", "Performance")}</b>
        </Typography>
        <Divider className={classes.sectionTitleDividers} />
        {selectedConsumerUnit ? (
          <PerformanceChart brConsumerUnitId={selectedConsumerUnit} />
        ) : (
          <Grid
            container
            className={classes.sections}
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "300px" }}
          >
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    );
  };
  const renderAlerts = () => {
    if (data.loading) {
      return null;
    }
    return (
      <Grid
        container
        component={Paper}
        className={classes.sections}
        style={{ marginBottom: "2rem" }}
      >
        <Typography variant="body1">
          <b>Alerts</b>
        </Typography>
        <Divider className={classes.sectionTitleDividers} />
      </Grid>
    );
  };

  const renderSwitchAccountDialog = () => {
    return (
      <Dialog fullScreen={props.fullScreen} open={!!switchAccountOpen}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>{i18n.t("switchAccount", "Switch Account")}</Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setSwitchAccountOpen(false);
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <List>
            {brContact?.brContactsBrCustomers?.map((customer, i) => (
              <ListItem
                button
                key={`customer-selector-list-item-${customer.id}`}
                selected={selectedCustomer?.id === customer.id}
                // divider={
                //   i !== curCustomerData.brCustomer.brConsumerUnits.length - 1
                // }
                style={{ borderRadius: "1rem" }}
                onClick={() => {
                  setSelectedCustomer(customer.brCustomer);
                  setSelectedConsumerUnit(null);
                }}
              >
                <ListItemText
                  primary={customer.brCustomer.name}
                  secondary={`${
                    customer.brCustomer.brConsumerUnits.length
                  } Consumer Unit${
                    customer.brCustomer.brConsumerUnits.length > 1 ? "s" : ""
                  }`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSwitchAccountOpen(false)}>
            {i18n.t("close", "Close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderCreateConsumerUnitDialog = () => {
    return (
      <Dialog
        open={!!(consumerUnitCreateDialogOpen && selectedCustomer)}
        fullScreen
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h5">
                    {i18n.t("addConsumerUnit", "Add Consumer Unit")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    {selectedCustomer?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setConsumerUnitCreateDialogOpen(false);
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <ConsumerUnitCreateForm
            brCustomerId={selectedCustomer?.id}
            onClose={() => {
              setConsumerUnitCreateDialogOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const showAlerts = false;

  return (
    <>
      <Grid
        container
        justifyContent="center"
        // alignItems="center"
        style={{ background: theme.palette.primary.main, padding: "2rem 1rem" }}
      >
        <Grid item xs={12} lg={10}>
          <Grid container spacing={sectionSpacing}>
            <Grid item xs={12} lg={4}>
              {renderDashboardMenu()}
            </Grid>
            <Grid item xs={12} lg={8}>
              <Collapse in={showAlerts}>{renderAlerts()}</Collapse>
              <Grid container spacing={sectionSpacing}>
                <Grid item xs={12} md={6}>
                  {renderAccountSummary()}
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderInvoices()}
                </Grid>
                {!data.loading && !selectedConsumerUnit ? null : (
                  <Grid item xs={12}>
                    {renderPerformance()}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {manageContactsCustomerId && (
        <UserManagementDialog
          brCustomerId={manageContactsCustomerId}
          open={!!manageContactsCustomerId}
          onClose={() => setManageContactsCustomerId(null)}
        />
      )}
      {renderSwitchAccountDialog()}
      {renderCreateConsumerUnitDialog()}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  graphql(logToSlackMutation, { name: "logToSlack" })(
    graphql(dashboardQuery)(withMobileDialog()(withTranslator(Dashboard)))
  )
);
