// https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/s3-example-creating-buckets.html#s3-create-presigendurl-put
import {
  S3Client,
  PutObjectCommand,
  DeleteObjectCommand,
} from "@aws-sdk/client-s3";

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

export const uploadObjectToS3 = async ({ file, key, bucket }) => {
  const bucketParams = {
    Bucket: bucket,
    Key: key,
    Body: file,
  };
  const command = new PutObjectCommand(bucketParams);
  return s3Client.send(command);
};

// If we want to use this, we need to update the CORs policy on the S3 buckets to allow DELETE
export const deleteObjectFromS3 = async ({ key, bucket }) => {
  try {
    const bucketParams = {
      Bucket: bucket,
      Key: key,
    };
    const command = new DeleteObjectCommand(bucketParams);
    return s3Client.send(command).then(
      (res) => {
        console.log(`Successfully deleted object from AWS S3 with key: ${key}`);
      },
      (e) => {
        console.error(e);
      }
    );
  } catch (err) {
    console.error("Error uploading file to S3", err);
  }
};
