/* -------------------------------------------------------------------------- */
/*                          Session Storage Functions                         */
/* -------------------------------------------------------------------------- */

export const safelyGetSessionStorageItem = (key) => {
  try {
    return sessionStorage.getItem(key);
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const safelySetSessionStorageItem = (key, value) => {
  try {
    return sessionStorage.setItem(key, value);
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const safelyRemoveSessionStorageItem = (key) => {
  try {
    return sessionStorage.removeItem(key);
  } catch (e) {
    console.error(e);
  }
  return null;
};

/* -------------------------------------------------------------------------- */
/*                           Local Storage Functions                          */
/* -------------------------------------------------------------------------- */

export const safelyGetLocalStorageItem = (key) => {
  try {
    return sessionStorage.getItem(key);
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const safelySetLocalStorageItem = (key, value) => {
  try {
    return sessionStorage.setItem(key, value);
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const safelyRemoveLocalStorageItem = (key) => {
  try {
    return sessionStorage.removeItem(key);
  } catch (e) {
    console.error(e);
  }
  return null;
};
