// cachebuster: 1.0.4
import React from "react";
import { Route, Navigate, Routes, useNavigate } from "react-router-dom";

import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
// import withTranslator from "./components/hocs/withTranslator";
import Home from "./components/Home";

// import withTranslator from "./components/hocs/withTranslator";

// import HowItWorks from "./components/HowItWorks";
// import SavingsCalculator from "./components/SavingsCalculator";
import Register from "./components/Register-New";
// import LearnMore from './components/LearnMore';
// import WhoWeAre from "./components/WhoWeAre";
// import Clients from "./components/Clients";
// import Projects from "./components/Projects";
// import Benefits from "./components/Benefits";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
// import TempDashboardOverride from "./components/TempDashboardOverride";
import Settings from "./components/Settings";

// import Faq from "./components/Faq";
// import Contact from "./components/Contact";
import withHeader from "./components/hocs/withHeader";
import withRequiredAuth from "./components/hocs/withRequiredAuth";

const HomeWithHeader = withHeader(Home, { footer: true, opaque: true });
const LoginComponent = withHeader(Login, { fullPage: true, opaque: true });
const DashboardWithHeader = withHeader(withRequiredAuth(Dashboard), {
  footer: true,
  opaque: true,
});
const SettingsWithHeader = withHeader(withRequiredAuth(Settings), {
  footer: true,
  opaque: true,
});
const RegisterWithHeader = withHeader(Register, { footer: true, opaque: true });

const App = () => {
  const oktaAuth = new OktaAuth({
    url: `https://${process.env.REACT_APP_OKTA_ORG_URL}`,
    issuer: `https://${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/${process.env.REACT_APP_OKTA_AUTH_SERVER_ID}`,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + "/implicit/callback",
    pkce: true,
  });
  const navigate = useNavigate();
  const onAuthRequired = async (oktaAuth) => {
    // safelySetSessionStorageItem('energeaLoginRedirect', location.href);
    await oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/login`,
    });
  };
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const lintedOriginalUri = originalUri || "/dashboard?welcome=1";
    navigate(toRelativeUrl(lintedOriginalUri, window.location.origin), {
      replace: true,
    });
  };
  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route exact path="/" element={<HomeWithHeader />} />
        <Route path="/implicit/callback" element={<LoginCallback />} />
        <Route exact path="/login" element={<LoginComponent fullPage />} />
        <Route
          exact
          path="/dashboard"
          element={<DashboardWithHeader footer />}
        />
        <Route path="/settings/*" element={<SettingsWithHeader footer />} />
        <Route exact path="/register" element={<RegisterWithHeader />} />

        {/* <Route exact path="/home" component={Home} />
      <Route exact path="/how-it-works" component={HowItWorks} />
      <Route exact path="/savings-calculator" component={SavingsCalculator} />
      <Route exact path="/benefits" component={Benefits} />
      <Route exact path="/who-we-are" component={WhoWeAre} />
      <Route exact path="/clients" component={Clients} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/contact" component={Contact} /> */}
        <Route exact path="/*" element={<Navigate to="/" />} />
      </Routes>
    </Security>
  );
};

export default App;
