import React, { Component } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import classNames from "classnames";
import validator from "validator";
import moment from "moment";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  Hidden,
  Icon,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  withMobileDialog,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import NumberFormat from "react-number-format";
import { cpf, cnpj } from "cpf-cnpj-validator";

import { constants } from "../global";
import { uploadObjectToS3 } from "../lib/aws";
import withSnackbar from "./hocs/withSnackbar";
import {
  safelyGetSessionStorageItem,
  safelySetSessionStorageItem,
} from "../lib/browser";

const submitContactToSalesforceMutation = loader(
  "../mutations/SubmitContactToSalesforce.graphql"
);
// const validateCustomerBillMutation = loader(
//   "../mutations/ValidateCustomerBill.graphql"
// );

const styles = (theme) => ({});

const PhoneFormat = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: { ...values, name: props.name },
        });
      }}
      isNumericString
      format="+55 ## ####-####"
    />
  );
};

const CPFFormat = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: { ...values, name: props.name },
        });
      }}
      isNumericString
      format="###.###.###-##"
    />
  );
};

const CNPJFormat = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: { ...values, name: props.name },
        });
      }}
      isNumericString
      format="##.###.###/####-##"
    />
  );
};

const formLabelColor = "rgba(0, 0, 0, 0.54)";
const inputLabelTextVariant = "body2";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iframeLoading: true,
      billUploading: false,
      activeStep: 0,

      // inputs:
      type: null,
      distribuidora: null,
      cpf: null,
      cnpj: null,
      firstName: null,
      lastName: null,
      telefone: null,
      email: null,
      companyName: null,
      nire: null,
      rgRne: null,
      orgaoExpedidor: null,
      dob: null,
      nationality: null,
      uploadedBills: [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  handleSubmit() {
    const {
      type,
      distribuidora,
      cpf,
      cnpj,
      firstName,
      lastName,
      telefone,
      email,
      companyName,
      nire,
      orgaoExpedidor,
      uploadedBills,
      civilStatus,
      dob,
      rgRne,
      nationality,
    } = this.state;
    const { submitContactToSalesforce, snackbar } = this.props;

    let input = {};
    if (type === "personal") {
      input = {
        distribuidora,
        cpf,
        firstName,
        lastName,
        telefone,
        email,
        billAwsObjectKeys: uploadedBills.map((bill) => bill.awsObjectKey),
        rgRne,
        orgaoExpedidor,
        dob,
        nationality,
        civilStatus,
      };
    } else {
      input = {
        distribuidora,
        cpf,
        firstName,
        lastName,
        telefone,
        email,
        billAwsObjectKeys: uploadedBills.map((bill) => bill.awsObjectKey),
        rgRne,
        orgaoExpedidor,
        dob,
        nationality,
        civilStatus,
        cnpj,
        companyName,
        nire,
        rgRne,
        orgaoExpedidor,
      };
    }
    this.setState({ loading: true });
    submitContactToSalesforce({
      variables: { input },
    }).then(
      () => {
        this.setState({ loading: false });

        snackbar.setState({
          snackbarMessage: `Information submitted. We will be in touch shortly!`,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        this.handleClose();
      },
      (err) => {
        console.error(err);
        snackbar.setState({
          snackbarMessage: `Error submitting information. Please try again later or contact us at contato@energea.com`,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
        this.setState({ loading: false });
      }
    );
  }

  handleClose() {
    const { onClose } = this.props;
    this.setState({
      type: null,
      distribuidora: null,
      cpf: null,
      cnpj: null,
      firstName: null,
      lastName: null,
      telefone: null,
      email: null,
      companyName: null,
      nire: null,
      rgRne: null,
      orgaoExpedidor: null,
      dob: null,
      nationality: null,
      civilStatus: null,
      activeStep: 0,
      uploadedBills: [],
    });
    onClose();
  }

  handleBack() {
    const { activeStep } = this.state;
    switch (activeStep) {
      case 0:
        this.setState({ type: null });
        break;
      case 1:
      default:
        this.setState({ activeStep: activeStep - 1 });
        break;
    }
  }

  handleNext() {
    const { activeStep, type } = this.state;
    switch (activeStep) {
      case 0:
      case 1:
        this.setState({ activeStep: activeStep + 1 });
        break;
      case 2:
        if (type === "personal") {
          this.handleSubmit();
        } else {
          this.setState({ activeStep: activeStep + 1 });
        }
        break;
      case 3:
        if (type === "business") {
          this.handleSubmit();
        }
        break;
      default:
        this.setState({ activeStep: activeStep + 1 });
        break;
    }
  }

  renderStepper() {
    const { activeStep, type } = this.state;
    if (!type) return null;
    switch (activeStep) {
      case 0:
        return this.renderStep0();
      case 1:
        return this.renderStep1();
      case 2:
        if (type === "personal") {
          return this.renderSynchronizeStep();
        }
        return this.renderStep2();
      case 3:
        return this.renderSynchronizeStep();
      default:
        return null;
    }
  }

  renderActionButtons() {
    const { loading, billUploading } = this.state;
    return (
      <Grid
        item
        container
        justifyContent="flex-end"
        style={{ marginTop: "1rem" }}
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={() => {
              this.handleBack();
            }}
            disabled={loading || billUploading}
          >
            Voltar
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              this.handleNext();
            }}
            disabled={(false && this.getContinueDisabled()) || loading}
          >
            {loading ? (
              <CircularProgress
                style={{ position: "absolute", color: "white" }}
              />
            ) : null}{" "}
            Continuar
          </Button>
        </Grid>
      </Grid>
    );
  }

  renderNIREInput() {
    const { nire } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          NIRE
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o NIRE"
            fullWidth
            value={nire || ""}
            onChange={(event) =>
              this.setState({
                nire: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderCompanyNameInput() {
    const { companyName } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Razão Social
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Razão social da empresa"
            fullWidth
            value={companyName || ""}
            onChange={(event) =>
              this.setState({
                companyName: event.target.value,
              })
            }
            error={companyName && !this.validate("companyName", companyName)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderCNPJInput() {
    const { cnpj } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          CNPJ
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o CNPJ"
            fullWidth
            value={cnpj || ""}
            onChange={(event) =>
              this.setState({
                cnpj: event.target.value,
              })
            }
            InputProps={{
              inputComponent: CNPJFormat,
            }}
            error={cnpj && !this.validate("cnpj", cnpj)}
          />
        </FormControl>
      </Grid>
    );
  }
  renderDistribuidoraInput() {
    const { distribuidora } = this.state;
    return (
      <Grid item>
        <FormControl required fullWidth>
          {/* <Typography
            style={{ color: formLabelColor }}
            gutterBottom
            variant={inputLabelTextVariant}
          >
            Distribuidora
          </Typography> */}
          <Select
            fullWidth
            variant="outlined"
            id="distribuidora"
            name="distribuidora"
            required
            value={distribuidora || "placeholder"}
            onChange={(event) =>
              this.setState({
                distribuidora: event.target.value,
              })
            }
            renderValue={(val) => {
              if (val === "placeholder")
                return (
                  <Typography style={{ color: formLabelColor }}>
                    Escolha uma distribuidora *
                  </Typography>
                );
              return <Typography>{val}</Typography>;
            }}
          >
            <MenuItem
              key={`distribuidora-selector-light`}
              name="distribuidora-light"
              value="Light"
            >
              Light
            </MenuItem>
            <MenuItem
              key={`distribuidora-selector-cemig`}
              name="distribuidora-cemig"
              value="CEMIG"
            >
              CEMIG
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  }

  renderRgRneInput() {
    const { rgRne } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          RG/RNE
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o RG/RNE"
            fullWidth
            value={rgRne || ""}
            onChange={(event) =>
              this.setState({
                rgRne: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderOrgaoExpedidorInput() {
    const { orgaoExpedidor } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Órgão expedidor
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o órgão expedidor do documento"
            fullWidth
            value={orgaoExpedidor || ""}
            onChange={(event) =>
              this.setState({
                orgaoExpedidor: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderDOBInput() {
    const { dob } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Data de nascimento
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite a data de nascimento"
            fullWidth
            type="date"
            value={dob || ""}
            onChange={(event) =>
              this.setState({
                dob: event.target.value,
              })
            }
            InputLabelProps={{ shrink: true }}
            error={dob && !this.validate("dob", dob)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderNationalityInput() {
    const { nationality } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Nacionalidade
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite a nacionalidade"
            fullWidth
            value={nationality || ""}
            onChange={(event) =>
              this.setState({
                nationality: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderCivilStatusInput() {
    const { civilStatus } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Estado civil
        </Typography> */}
        <FormControl required fullWidth>
          <Select
            fullWidth
            variant="outlined"
            id="selecione-o-estado-civil"
            name="selecione-o-estado-civil"
            required
            // label="Test"
            // labelWidth={50}
            value={civilStatus || "placeholder"}
            onChange={(event) =>
              this.setState({
                civilStatus: event.target.value,
              })
            }
            renderValue={(val) => {
              if (val === "placeholder")
                return (
                  <Typography style={{ color: formLabelColor }}>
                    Selecione o estado civil *
                  </Typography>
                );
              if (val === `casado`) return <Typography>Casado (a)</Typography>;
              if (val === `solteiro`)
                return <Typography>Solteiro (a)</Typography>;
              if (val === `viúvo`) return <Typography>Viúvo (a)</Typography>;
              if (val === `divorciado`)
                return <Typography>Divorciado (a)</Typography>;
              return <Typography>{val}</Typography>;
            }}
          >
            <MenuItem
              key="civil-status-casado"
              name="civil-status-casado"
              value="casado"
            >
              Casado (a)
            </MenuItem>
            <MenuItem
              key="civil-status-solteiro"
              name="civil-status-solteiro"
              value="solteiro"
            >
              Solteiro (a)
            </MenuItem>
            <MenuItem
              key="civil-status-viúvo"
              name="civil-status-viúvo"
              value="viúvo"
            >
              Viúvo (a)
            </MenuItem>
            <MenuItem
              key="civil-status-divorciado"
              name="civil-status-divorciado"
              value="divorciado"
            >
              Divorciado (a)
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  }

  renderCPFInput() {
    const { cpf } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          CPF
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o CPF"
            fullWidth
            value={cpf || ""}
            onChange={(event) =>
              this.setState({
                cpf: event.target.value,
              })
            }
            InputProps={{
              inputComponent: CPFFormat,
            }}
            error={cpf && !this.validate("cpf", cpf)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderFirstNameInput() {
    const { firstName } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Nome próprio
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o nome próprio"
            fullWidth
            value={firstName || ""}
            onChange={(event) =>
              this.setState({
                firstName: event.target.value,
              })
            }
            error={firstName && !this.validate("firstName", firstName)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderLastNameInput() {
    const { lastName } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Nome apelidos
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o nome apelidos"
            fullWidth
            value={lastName || ""}
            onChange={(event) =>
              this.setState({
                lastName: event.target.value,
              })
            }
            error={lastName && !this.validate("lastName", lastName)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderTelefoneInput() {
    const { telefone } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Telefone (Whatsapp)
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o número do telefone"
            fullWidth
            value={telefone || ""}
            onChange={(event) =>
              this.setState({
                telefone: event.target.value,
              })
            }
            InputProps={{
              inputComponent: PhoneFormat,
            }}
            error={telefone && !this.validate("telefone", telefone)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderEmailInput() {
    const { email } = this.state;
    return (
      <Grid item>
        {/* <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          E-mail
        </Typography> */}
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o e-mail"
            fullWidth
            value={email || ""}
            onChange={(event) =>
              this.setState({
                email: event.target.value,
              })
            }
            error={email && !this.validate("email", email)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderStep0() {
    const { type } = this.state;

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            Insira os dados do titular da conta de energia
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {type === "personal" ? (
              <>
                {this.renderDistribuidoraInput()}
                {this.renderCPFInput()}
                {this.renderFirstNameInput()}
                {this.renderLastNameInput()}
                {this.renderTelefoneInput()}
                {this.renderEmailInput()}
              </>
            ) : (
              <>
                {this.renderDistribuidoraInput()}
                {this.renderCNPJInput()}
                {this.renderCompanyNameInput()}
                {this.renderNIREInput()}
              </>
            )}
          </Grid>
        </Grid>
        {this.renderActionButtons()}
      </Grid>
    );
  }

  renderStep1() {
    const { type } = this.state;
    const title =
      type === "personal"
        ? "Insira os dados do titular da conta de energia"
        : "Dados do Representante Legal";
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {type === "personal" ? (
              <>
                {this.renderRgRneInput()}
                {this.renderOrgaoExpedidorInput()}
                {this.renderDOBInput()}
                {this.renderNationalityInput()}
                {this.renderCivilStatusInput()}
              </>
            ) : (
              <>
                {this.renderCPFInput()}
                {this.renderFirstNameInput()}
                {this.renderLastNameInput()}
                {this.renderTelefoneInput()}
                {this.renderDOBInput()}
                {this.renderEmailInput()}
              </>
            )}
          </Grid>
        </Grid>
        {this.renderActionButtons()}
      </Grid>
    );
  }

  renderStep2() {
    const { type } = this.state;
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            Dados do Representante Legal
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {type === "personal" ? null : (
              <>
                {this.renderRgRneInput()}
                {this.renderOrgaoExpedidorInput()}
                {this.renderNationalityInput()}
                {this.renderCivilStatusInput()}
              </>
            )}
          </Grid>
        </Grid>
        {this.renderActionButtons()}
      </Grid>
    );
  }

  renderSynchronizeStep() {
    const { snackbar, validateCustomerBill } = this.props;
    const { billUploading, uploadedBills, loading, distribuidora } = this.state;

    const clientAllowedFormats = "application/pdf";

    return (
      <Grid direction="column" style={{ width: "100%" }} alignItems="center">
        <Grid
          xs={12}
          item
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
            Carregue a sua fatura de eletricidade mais recente para cada
            instalação
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={() => this.setState({ uploadBillsSelected: true })}
              > */}
              <Button
                onClick={() => this.setState({ uploadBillsSelected: true })}
                variant="contained"
                disabled={billUploading || loading}
                size="large"
                color="primary"
                component="label" // https://stackoverflow.com/a/54043619
              >
                {loading || billUploading ? (
                  <CircularProgress
                    style={{ position: "absolute", color: "white" }}
                  />
                ) : null}{" "}
                Carregar fatura(s)
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={async (event) => {
                    const {
                      target: { validity, files },
                    } = event;
                    const fileArray = Array.from(files);
                    if (fileArray.length > 10) {
                      snackbar.setState({
                        snackbarMessage:
                          "Você pode selecionar no máximo 10 boletos.",
                        // "You can only upload 10 bills at a time.",
                        snackbarOpen: true,
                        snackbarVariant: "error",
                      });
                      return;
                    }
                    if (!validity.valid) {
                      snackbar.setState({
                        snackbarMessage:
                          "There was an issue uploading your bill(s). Please try again shortly or reach out if the issue persists.",
                        snackbarOpen: true,
                        snackbarVariant: "error",
                      });
                      return;
                    }

                    const uploadAttempts = parseInt(
                      safelyGetSessionStorageItem(
                        "utility-bill-upload-attempts"
                      ) || 0,
                      10
                    );
                    if (uploadAttempts >= 100) {
                      snackbar.setState({
                        snackbarMessage:
                          "You have uploaded too many invalid bills. Email contato@energea.com for assistance.",
                        snackbarOpen: true,
                        snackbarVariant: "error",
                      });
                      return;
                    }
                    safelySetSessionStorageItem(
                      "utility-bill-upload-attempts",
                      uploadAttempts + fileArray.length
                    );

                    for (let index = 0; index < fileArray.length; index++) {
                      const file = files[parseInt(index, 10)];
                      const fileSize = file.size / 1024 / 1024; // in MiB
                      if (fileSize > 10) {
                        snackbar.setState({
                          snackbarMessage:
                            "All documents must be less than 10MB.",
                          snackbarOpen: true,
                          snackbarVariant: "error",
                        });
                        return;
                      }
                    }
                    const timestamp = new Date().getTime();
                    this.setState({ billUploading: true });
                    for (let index = 0; index < fileArray.length; index++) {
                      const file = files[parseInt(index, 10)];
                      const awsObjectKey = `NEW_LEAD_BILLS/${timestamp}-${index}-${file.name}`;
                      await uploadObjectToS3({
                        file,
                        key: awsObjectKey,
                        bucket: process.env.REACT_APP_S3_BUCKET,
                      }).then(
                        () => {
                          return validateCustomerBill({
                            variables: {
                              input: { awsObjectKey, distribuidora },
                            },
                          }).then(
                            (res) => {
                              if (res?.data?.validateCustomerBill) {
                                const updatedBillList = uploadedBills;
                                updatedBillList.push({
                                  awsObjectKey,
                                  fileName: file.name,
                                });
                                this.setState({
                                  uploadedBills: updatedBillList,
                                });
                              } else {
                                snackbar.setState({
                                  snackbarMessage: `Invalid ${distribuidora} bill (${file.name}). Please try again later or send the document directly to contato@energea.com.`,
                                  snackbarOpen: true,
                                  snackbarVariant: "error",
                                });
                              }
                            },
                            (err) => {
                              console.error(err);
                              snackbar.setState({
                                snackbarMessage:
                                  "Error validating document. Please try again later or send the document directly to contato@energea.com.",
                                snackbarOpen: true,
                                snackbarVariant: "error",
                              });
                            }
                          );
                        },
                        (err) => {
                          console.error(err);
                          snackbar.setState({
                            snackbarMessage:
                              "Error uploading document. Please try again later or send the document directly to contato@energea.com.",
                            snackbarOpen: true,
                            snackbarVariant: "error",
                          });
                          this.setState({
                            uploadedBills: [],
                            billUploading: false,
                          });
                        }
                      );
                    }
                    this.setState({ billUploading: false });
                  }}
                  accept={clientAllowedFormats}
                />
                {/* <Typography
                  color="inherit"
                  variant="body1"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Upload Bill(s)
                </Typography> */}
              </Button>
            </Grid>
            <Collapse in={uploadedBills.length > 0}>
              <Grid
                item
                container
                direction="column"
                style={{ margin: "1rem 0" }}
              >
                {uploadedBills.map((bill, index) => (
                  <Grid item>
                    <Typography variant="body2">{bill.fileName}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Collapse>
            <Collapse in={uploadedBills.length === 0}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Grid item style={{ margin: "1rem 0" }}>
                  <Typography variant="body2">ou</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                    disabled={billUploading}
                  >
                    <Typography
                      color="inherit"
                      variant="body1"
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      // className={classes.typeText}
                    >
                      pule por enquanto
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Collapse>
            {this.renderActionButtons()}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  validate(attr, val) {
    switch (attr) {
      case "firstName":
      case "lastName":
        return val && val.length >= 2;
      case "cpf":
        return cpf.isValid(val);
      case "cnpj":
        return cnpj.isValid(val);
      case "email":
        return validator.isEmail(val);
      case "telefone":
        return validator.isMobilePhone(val, "pt-BR");
      case "dob":
        return moment(val).isValid() && moment(val).isBefore(moment());
      case "companyName":
        return val && val.length >= 2;
      default:
        return true;
    }
  }

  getContinueDisabled() {
    const {
      activeStep,
      type,
      distribuidora,
      cpf,
      cnpj,
      firstName,
      lastName,
      telefone,
      email,
      companyName,
      nire,
      rgRne,
      nationality,
      civilStatus,
      dob,
      orgaoExpedidor,
      uploadedBills,
      uploadBillsSelected,
      billUploading,
    } = this.state;
    switch (activeStep) {
      case 0: {
        if (type === "personal") {
          return (
            distribuidora === null ||
            cpf === null ||
            !this.validate("cpf", cpf) ||
            firstName === null ||
            !this.validate("firstName", firstName) ||
            lastName === null ||
            !this.validate("lastName", lastName) ||
            telefone === null ||
            !this.validate("telefone", telefone) ||
            email === null ||
            !this.validate("email", email)
          );
        }
        if (type === "business") {
          return (
            distribuidora === null ||
            cnpj === null ||
            !this.validate("cnpj", cnpj) ||
            companyName === null ||
            !this.validate("companyName", companyName) ||
            nire === null
          );
        }
        return true;
      }
      case 1:
        if (type === "personal") {
          return (
            rgRne === null ||
            orgaoExpedidor === null ||
            dob === null ||
            !this.validate("dob", dob) ||
            nationality === null ||
            civilStatus === null
          );
        }
        if (type === "business") {
          return (
            dob === null ||
            !this.validate("dob", dob) ||
            cpf === null ||
            !this.validate("cpf", cpf) ||
            firstName === null ||
            !this.validate("firstName", firstName) ||
            lastName === null ||
            !this.validate("lastName", lastName) ||
            telefone === null ||
            !this.validate("telefone", telefone) ||
            email === null ||
            !this.validate("email", email)
          );
        }
        return true;
      case 2:
        if (type === "personal") {
          return (
            !uploadBillsSelected ||
            (uploadBillsSelected && !uploadedBills?.length) ||
            billUploading
          );
        }
        if (type === "business") {
          return (
            rgRne === null ||
            orgaoExpedidor === null ||
            nationality === null ||
            civilStatus === null
          );
        }
      case 3:
        if (type === "business") {
          return (
            !uploadBillsSelected ||
            (uploadBillsSelected && !uploadedBills?.length) ||
            billUploading
          );
        }
      default:
        return false;
    }
  }

  render() {
    const { open, fullScreen, theme } = this.props;
    const { iframeLoading, activeStep, type } = this.state;
    const { bblform } = queryString.parse(window.location.search);

    return (
      <Dialog fullScreen open={!!open} onClose={this.handleClose}>
        <DialogContent style={{ padding: 0 }}>
          <Grid container>
            <Grid item container>
              <Hidden smDown>
                <Grid
                  style={{
                    backgroundSize: "cover",
                    height: "100%",
                    position: "relative",
                    backgroundImage:
                      "url(https://res.cloudinary.com/dwelloptimal/image/upload/v1654724323/energea/br-images/solar-panel-in-perspective-2022-03-04-01-48-32-utc_1.jpg)",
                  }}
                  item
                  md={4}
                  xs={0}
                >
                  <Grid
                    container
                    style={{ marginTop: "2.5rem", width: "100%" }}
                    justifyContent="center"
                  >
                    <Grid item>
                      <Grid
                        container
                        style={{ width: "100%", height: "40vh" }}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                      >
                        <Grid item>
                          <img
                            style={{
                              position: "absolute",
                              top: 40,
                              right: -81,
                            }}
                            src="https://res.cloudinary.com/dwelloptimal/image/upload/v1654724967/energea/br-images/selo.png"
                          />
                          <Link to="/" className="no-select">
                            <img
                              style={{
                                marginRight: "1.5rem",
                                marginTop: "-6px",
                                verticalAlign: "middle",
                              }}
                              width={constants.headerLogoWidth}
                              src={constants.headerLogoUrl}
                              alt="Logo"
                            />
                          </Link>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h1"
                            style={{
                              fontSize: "32px",
                              fontWeight: 400,
                              color: "#fff",
                              width: "100%",
                            }}
                          >
                            Onde você quer
                          </Typography>
                          <Typography
                            variant="h1"
                            style={{
                              color: theme.palette.green1.main,
                              fontSize: "48px",
                              lineHeight: "135%",
                            }}
                          >
                            economizar?
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Grid
                item
                md={8}
                xs={12}
                style={{
                  padding: "2rem 1rem 0",
                  height: "100vh",
                  position: "relative",
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  style={{ height: "100%", overflow: "scroll" }}
                >
                  <Grid item md={9} xs={12} style={{ height: "100%" }}>
                    <Button
                      style={{
                        color: theme.palette.green2.main,
                        marginBottom: "32px",
                      }}
                      variant="text"
                      onClick={this.handleClose}
                      startIcon={<ArrowBackIos />}
                    >
                      Voltar para o site
                    </Button>
                    <Typography
                      gutterBottom
                      color="primary"
                      variant="h2"
                      style={{ marginBottom: "2rem" }}
                    >
                      Cadastre-Se
                    </Typography>
                    {!bblform ? (
                      <Grid container>
                        <Collapse in={type === null}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              style={{
                                padding: fullScreen ? "1rem 0" : "2rem 0",
                              }}
                            >
                              <Typography
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                                variant={fullScreen ? "body1" : "h6"}
                              >
                                Vamos lá, selecione o tipo de unidade que deseja
                                sincronizar
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                              >
                                <Grid item xs={12} md={6}>
                                  <Card
                                    elevation={2}
                                    style={{
                                      minWidth: fullScreen ? "9rem" : "12rem",
                                      margin: ".5rem",
                                    }}
                                  >
                                    <CardActionArea
                                      style={{
                                        padding: fullScreen ? 0 : "1rem 0",
                                      }}
                                      onClick={() =>
                                        this.setState({ type: "personal" })
                                      }
                                    >
                                      <CardContent>
                                        <Grid
                                          container
                                          direction="column"
                                          alignItems="center"
                                          justifyContent="center"
                                          style={{ textAlign: "center" }}
                                          spacing={fullScreen ? 1 : 3}
                                        >
                                          <Grid item>
                                            <Icon
                                              className={classNames(
                                                "fa-light",
                                                "fa-house-chimney"
                                              )}
                                              style={{
                                                fontSize: fullScreen
                                                  ? "2rem"
                                                  : "3rem",
                                              }}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            style={{
                                              height: fullScreen
                                                ? "2rem"
                                                : "3rem",
                                              minWidth: "5rem",
                                            }}
                                          >
                                            <Typography
                                              color="inherit"
                                              variant="h6"
                                              style={{
                                                fontWeight: "bold",
                                                // textAlign: "center",
                                              }}
                                              // className={classes.typeText}
                                            >
                                              Imóvel de Pessoa Física
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            style={{
                                              height: fullScreen
                                                ? "3rem"
                                                : "5rem",
                                              minWidth: "5rem",
                                            }}
                                          >
                                            <Typography
                                              color="inherit"
                                              variant="body1"
                                              // style={{ textAlign: "center" }}
                                              // className={classes.typeText}
                                            >
                                              Unidade consumidora cadastrada
                                              para um titular com CPF
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Card
                                    style={{
                                      minWidth: fullScreen ? "9rem" : "12rem",
                                      margin: ".5rem",
                                    }}
                                    elevation={2}
                                  >
                                    <CardActionArea
                                      style={{
                                        padding: fullScreen ? 0 : "1rem 0",
                                      }}
                                      onClick={() =>
                                        this.setState({ type: "business" })
                                      }
                                    >
                                      <CardContent>
                                        <Grid
                                          container
                                          direction="column"
                                          alignItems="center"
                                          justifyContent="center"
                                          style={{ textAlign: "center" }}
                                          spacing={fullScreen ? 1 : 3}
                                        >
                                          <Grid item>
                                            <Icon
                                              className={classNames(
                                                "fa-sharp",
                                                "fa-light",
                                                "fa-city"
                                              )}
                                              style={{
                                                fontSize: fullScreen
                                                  ? "2rem"
                                                  : "3rem",
                                              }}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            style={{
                                              height: fullScreen
                                                ? "2rem"
                                                : "3rem",
                                              minWidth: "5rem",
                                            }}
                                          >
                                            <Typography
                                              variant="h6"
                                              color="inherit"
                                              // className={classes.typeText}
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Imóvel de Pessoa Jurídica
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            style={{
                                              height: fullScreen
                                                ? "3rem"
                                                : "5rem",
                                              minWidth: "5rem",
                                            }}
                                          >
                                            <Typography
                                              color="inherit"
                                              variant="body1"
                                              // className={classes.typeText}
                                            >
                                              Unidade consumidora cadastrada
                                              para um titular CNPJ
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Collapse>
                        <Collapse
                          in={type === "personal"}
                          style={{ width: "100%" }}
                        >
                          <Stepper
                            style={{ paddingTop: 0 }}
                            alternativeLabel
                            activeStep={activeStep}
                          >
                            {[1, 2].map((step) => (
                              <Step key={String(step)}>
                                <StepLabel />
                              </Step>
                            ))}
                          </Stepper>
                          <Grid container justifyContent="center">
                            <Grid
                              item
                              container
                              xs={12}
                              lg={10}
                              // spacing={5}
                              justifyContent="center"
                              // alignItems="center"
                            >
                              {this.renderStepper()}
                            </Grid>
                          </Grid>
                        </Collapse>
                        <Collapse
                          in={type === "business"}
                          style={{ width: "100%" }}
                        >
                          <Stepper
                            style={{ paddingTop: 0 }}
                            alternativeLabel
                            activeStep={activeStep}
                          >
                            {[1, 2, 3].map((step) => (
                              <Step key={String(step)}>
                                <StepLabel />
                              </Step>
                            ))}
                          </Stepper>
                          <Grid container justifyContent="center">
                            <Grid
                              item
                              container
                              xs={12}
                              lg={10}
                              // spacing={5}
                              justifyContent="center"
                              // alignItems="center"
                            >
                              {this.renderStepper()}
                            </Grid>
                          </Grid>
                        </Collapse>
                      </Grid>
                    ) : (
                      <Grid item xs={12} style={{ height: "100%" }}>
                        {iframeLoading ? (
                          <Grid
                            container
                            alignItems="center"
                            style={{ position: "relative" }}
                          >
                            <Grid
                              style={{
                                width: "100%",
                                position: "absolute",
                                height: "100%",
                                textAlign: "center",
                              }}
                            >
                              <CircularProgress />
                            </Grid>
                          </Grid>
                        ) : null}
                        <iframe
                          name="form"
                          title="Registration Form"
                          // src="https://portal.energea.com.br/novolead"
                          src="https://portal.energea.com.br/auto-servir"
                          frameborder="0"
                          scrolling="auto"
                          onLoad={() => {
                            this.setState({ iframeLoading: false });
                          }}
                          style={{ width: "100%", height: "100%" }}
                          class="frame-area cruze-frame"
                        ></iframe>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withSnackbar(
  graphql(submitContactToSalesforceMutation, {
    name: "submitContactToSalesforce",
  })(
    // graphql(validateCustomerBillMutation, {
    //   name: "validateCustomerBill",
    // })(
    withStyles(styles, { withTheme: true })(withMobileDialog()(Register), {
      whiteBackground: true,
    })
  )
  // )
);
