import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { OktaAuth } from "@okta/okta-auth-js";

const oktaAuth = new OktaAuth({
  url: `https://${process.env.REACT_APP_OKTA_ORG_URL}`,
  issuer: `https://${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/${process.env.REACT_APP_OKTA_AUTH_SERVER_ID}`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/implicit/callback",
  pkce: true,
});
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_PATH}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  const accessToken = await oktaAuth.tokenManager.get("accessToken");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken.accessToken}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  name: `energea-br-portal-${process.env.NODE_ENV}`, // NOTE: don't change this
  version: "1.1",
  connectToDevTools: process.env.NODE_ENV === "development",
  dataIdFromObject: (o) => o.id, // every object runs through this and determines the id as the id attribute
});

export default client;
