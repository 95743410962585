import { createTheme } from "@material-ui/core/styles";

const defaultTheme = createTheme({
  // use as reference for theme below
  typography: {
    useNextVariants: true, // necessary to bi-pass variant outdated warning
  },
});

const theme = createTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: "24px",
        overflow: "hidden !important",
      },
    },
    MuiListItemText: {
      primary: {
        // fontWeight: '700'
      },
    },
    // MuiListItem: {
    //   divider: {
    //     backgroundColor: 'rgba(255, 255, 255, 0.12)'
    //   }
    // },
    MuiOutlinedInput: {
      root: {
        borderRadius: "8px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        fontWeight: "700",
        borderRadius: "8px",
        fontSize: "14px",
        padding: "12px 16px",
      },
      sizeLarge: {
        fontSize: "16px",
        padding: "16px 24px",
      },
      containedSecondary: {
        color: "#fff",
      },
    },
    MuiCard: {
      root: {
        overflow: "visible",
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: ".5em",
      },
      h1: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        lineHeight: "150%",
        fontWeight: "700",
        fontSize: "40px",
        [defaultTheme.breakpoints.down("sm")]: {
          fontSize: "2rem",
        },
      },
      h2: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: "32px",
        fontWeight: "700",
        [defaultTheme.breakpoints.down("sm")]: {
          fontSize: "2rem",
        },
      },
      h3: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontWeight: "400",
        fontSize: "24px",
        lineHeight: "36px",
      },
      h4: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
      },
      h5: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
      },
      body1: {
        fontSize: "16px",
      },
      body2: {
        fontSize: "14px",
      },
    },
    MuiTablePagination: {
      root: {
        float: "left",
      },
    },
  },
  palette: {
    primary: {
      main: "#1B2C4A",
      light: "#324867",
    },
    secondary: {
      main: "#3AB364",
      light: "#009A7C",
    },
    green1: {
      main: "#8CC63F",
    },
    green2: {
      main: "#3AB364",
    },
    green3: {
      main: "#009A7C",
    },
    green4: {
      main: "#007F83",
    },
    blue1: {
      main: "#1B2C4A",
    },
    blue2: {
      main: "#273A58",
    },
    blue3: {
      main: "#324867",
    },
    blue4: {
      main: "#6884AB",
    },
    success: {
      main: "#0e9e23",
    },
    gray: {
      main: "#EEEFF3",
    },
    gradient: {
      main: "linear-gradient(225deg, #9BD67A 0%, #008A83 100%)",
    },
    error: {
      main: "#7F2B23",
      light: "#e96363",
      dark: "#8a0d01",
    },
  },
  typography: {
    // useNextVariants: true, // https://material-ui.com/style/typography/#migration-to-typography-v2
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
});

export default theme;
