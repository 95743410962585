import React, { useState } from "react";
import classnames from "classnames";
import { HashLink } from "react-router-hash-link";

import {
  Button,
  Divider,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withMobileDialog,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { constants } from "../global";
import withTranslator from "./hocs/withTranslator";

const styles = (theme) => ({
  root: {
    padding: "1em 24px",
    borderTop: "solid 1px #ddd",
    backgroundColor: theme.palette.primary.dark,
    color: "white",
  },
  socialLinks: {
    color: "#fff",
    padding: "2px 3px 3px 6px",
    "&:hover": { cursor: "pointer", color: theme.palette.secondary.main },
  },
  links: {
    fontWeight: "bold",
    color: "#fff",
    "&:hover": { color: theme.palette.secondary.main },
  },
  footerLinks: {
    color: "#fff",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  greenLink: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    "&:hover": {
      color: "#fff",
    },
    cursor: "pointer",
  },
});

const Footer = (props) => {
  const {
    classes,
    fullScreen,
    setSavingsCalculatorOpen,
    setContactFormOpen,
    i18n,
  } = props;
  const [partnerDialogOpen, setPartnerDialogOpen] = useState(false);

  const getLinks = () => {
    return [
      {
        label: i18n.t("howItWorks", "How it works"),
        slug: "como-funciona",
      },
      {
        label: i18n.t("aboutUs", "About Us"),
        slug: "quem-somos",
      },
      {
        label: i18n.t("projects", "Projects"),
        slug: "projetos",
      },
      {
        label: i18n.t("contact", "Contact"),
        slug: "contato", // used for a react key
        onClick: () => setContactFormOpen(true),
      },
      {
        label: i18n.t("simulateSavings", "Simulate Savings"),
        slug: "simulador-de-economia", // used for a react key
        onClick: () => setSavingsCalculatorOpen(true),
      },
    ];
  };
  if (fullScreen) {
    return (
      <>
        <Grid
          justifyContent="center"
          alignItems="center"
          container
          direction="column"
          className={classes.root}
          style={{ paddingTop: "50px", paddingBottom: "45px" }}
        >
          <Grid item>
            <img
              width="140px"
              src="https://res.cloudinary.com/dwelloptimal/image/upload/v1653078046/energea/br-images/energea_1.svg"
              alt="Logo"
            />
          </Grid>
          <Grid item style={{ margin: "1rem" }}>
            <Typography variant="h5" style={{ fontSize: "22px" }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook Profile"
                href={constants.facebookUrl}
              >
                <i
                  className={classnames([
                    "fab fa-facebook",
                    classes.socialLinks,
                  ])}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram Profile"
                href={constants.instagramUrl}
              >
                <i
                  className={classnames([
                    "fab fa-instagram",
                    classes.socialLinks,
                  ])}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Linked In Profile"
                href={constants.linkedInUrl}
              >
                <i
                  className={classnames([
                    "fab fa-linkedin",
                    classes.socialLinks,
                  ])}
                />
              </a>
            </Typography>
          </Grid>
          <Grid item>
            <Divider
              style={{
                width: "200px",
                background:
                  "linear-gradient(270deg, rgba(0, 59, 109, 0.5) -11.45%, #008A83 50.64%, rgba(0, 59, 109, 0.5) 111.45%)",
              }}
            />
          </Grid>
          <Grid item style={{ margin: "1rem" }}>
            <Typography
              variant="body2"
              onClick={() => {
                alert("Coming Soon Partner Page");
              }}
              className={classnames([classes.greenLink])}
            >
              <i
                className={classnames(["far", "fa-handshake-simple"])}
                style={{ marginRight: ".5rem" }}
              />
              Seja nosso parceiro
            </Typography>
          </Grid>
          <Grid item>
            <Divider
              style={{
                width: "200px",
                background:
                  "linear-gradient(270deg, rgba(0, 59, 109, 0.5) -11.45%, #008A83 50.64%, rgba(0, 59, 109, 0.5) 111.45%)",
              }}
            />
          </Grid>
          <Grid item style={{ marginTop: "1.5rem" }}>
            <Grid container>
              <Grid item>
                <Typography variant="caption" style={{ marginBottom: "1rem" }}>
                  <a
                    className={classes.links}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={constants.privacyPolicyUrl}
                  >
                    Política de Privacidade
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" style={{ margin: "0 .5rem" }}>
                  |
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" style={{ marginBottom: ".5rem" }}>
                  <a
                    className={classes.links}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={constants.termsOfServiceUrl}
                  >
                    Termos de Serviço
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: ".5rem" }}>
            <Typography
              style={{
                color: "#fff",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
              align="center"
              gutterBottom
              variant="caption"
            >
              {`©${new Date().getFullYear()} ${
                constants.globalCompanyName
              }. Todos os direitos reservados.`}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <>
      <Grid
        className={classes.root}
        justifyContent="space-between"
        alignItems="center"
        container
      >
        <Grid item>
          <Grid
            container
            item
            direction="row"
            // md={10}
            alignItems="center"
            xs={12}
            style={{ width: "100%" }}
          >
            <Grid item>
              <img
                style={{ marginTop: "4px", marginRight: "2rem" }}
                width="154px"
                src="https://res.cloudinary.com/dwelloptimal/image/upload/v1653078046/energea/br-images/energea_1.svg"
                alt="Logo"
              />
            </Grid>
            <Grid item>
              <Typography variant="h5">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook Profile"
                  href={constants.facebookUrl}
                >
                  <i
                    className={classnames([
                      "fab fa-facebook",
                      classes.socialLinks,
                    ])}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram Profile"
                  href={constants.instagramUrl}
                >
                  <i
                    className={classnames([
                      "fab fa-instagram",
                      classes.socialLinks,
                    ])}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Linked In Profile"
                  href={constants.linkedInUrl}
                >
                  <i
                    className={classnames([
                      "fab fa-linkedin",
                      classes.socialLinks,
                    ])}
                  />
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            {getLinks().map((link) => {
              let component = link.onClick ? Button : HashLink;
              let onClick = link.onClick || undefined;

              return (
                <Button
                  key={`footer-link-${link.slug}`}
                  component={component}
                  onClick={onClick}
                  className={classes.footerLinks}
                  variant="text"
                  to={!link.onClick ? `/#${link.slug}` : undefined}
                >
                  {link.label}
                </Button>
              );
            })}
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ width: "100%", margin: "1rem" }}>
          <Divider
            style={{
              width: "100%",
              background:
                "linear-gradient(270deg, rgba(0, 59, 109, 0.5) -11.45%, #008A83 50.64%, rgba(0, 59, 109, 0.5) 111.45%)",
            }}
          />
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <Grid container>
              <Grid item>
                <Typography variant="caption" style={{ marginBottom: "1rem" }}>
                  <a
                    className={classes.links}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={constants.privacyPolicyUrl}
                  >
                    Política de Privacidade
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" style={{ margin: "0 .5rem" }}>
                  |
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" style={{ marginBottom: ".5rem" }}>
                  <a
                    className={classes.links}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={constants.termsOfServiceUrl}
                  >
                    Termos de Serviço
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              style={{ color: "#fff", marginBottom: "1rem" }}
              align="center"
              gutterBottom
              variant="caption"
            >
              {`©${new Date().getFullYear()} ${
                constants.globalCompanyName
              }. Todos os direitos reservados.`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              onClick={() => {
                setPartnerDialogOpen(true);
              }}
              className={classnames([classes.greenLink])}
            >
              <i
                className={classnames(["far", "fa-handshake-simple"])}
                style={{ marginRight: ".5rem" }}
              />
              Seja nosso parceiro
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={!!partnerDialogOpen}
        onClose={() => setPartnerDialogOpen(false)}
      >
        <DialogTitle>Seja nosso parceiro</DialogTitle>
        <DialogContent>
          Interessado em se tornar um parceiro de vendas conosco e ajudar a
          oferecer economia de custos e responsabilidade ambiental a milhares de
          clientes? Por favor, entre em contato conosco através do e-mail{" "}
          <a href="mailto:contato@energea.com">contato@energea.com</a> para mais
          informações.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPartnerDialogOpen(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(withMobileDialog()(withTranslator(Footer)));
