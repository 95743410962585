// cache-2
import React, { useState, useEffect, useCallback } from "react";
import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Slide,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import { HashLink } from "react-router-hash-link";
import { ArrowDropDown, Close, Menu } from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

import AccountMenuBtn from "./AccountMenuBtn";
import TermsOfAdhesionSigningDialog from "./TermsOfAdhesionSigningDialog";
import { constants } from "../global";
import withTranslator from "./hocs/withTranslator";

const currentUserQuery = loader("../queries/CurrentUser.graphql");

const useStyles = makeStyles((theme) => ({
  desktopNavBtn: {
    color: "white",
    height: "64px",
    paddingLeft: "1.1rem",
    paddingRight: "1.1rem",
    borderRadius: 0,
    "&:hover": {
      color: theme.palette.secondary.main,
      background: "rgba(0,0,0,0)",
    },
  },
  socialLinks: {
    color: theme.palette.primary.light,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  scrollTop: {},
  opaque: {
    // position: "relative",
    background: `${theme.palette.primary.dark} !important`,
  },
  notScrollTop: {
    borderBottom: "1px solid #fff",
    background: `${theme.palette.primary.dark} !important`,
    transition: "background-color 0.5s ease",
  },

  // Repeat for other styles from the original component...
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Header = (props) => {
  const { i18n, setContactFormOpen, setSavingsCalculatorOpen, opaque, data } =
    props;
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [subLinksAnchorEl, setSubLinksAnchorEl] = useState(null);
  const [subLinks, setSubLinks] = useState(null);
  const { oktaAuth, authState } = useOktaAuth();
  const [scrollTop, setScrollTop] = useState(true);
  const isAuthenticated = authState?.isAuthenticated;
  const handleOpenSubLinks = useCallback(
    (subLinks) => (event) => {
      setSubLinks(subLinks);
      setSubLinksAnchorEl(event.currentTarget);
    },
    []
  );
  const handleScroll = () => {
    if (scrollTop !== (window.scrollY === 0)) {
      setScrollTop(window.scrollY === 0);
    }
  };
  window.addEventListener("scroll", handleScroll);
  // Define getLinks similar to the original class method
  const getLinks = () => {
    return [
      {
        label: i18n.t("howItWorks", "How it works"),
        slug: "#como-funciona",
        desktopPosition: "left",
      },
      {
        label: i18n.t("aboutUs", "About Us"),
        slug: "#quem-somos",
        desktopPosition: "left",
        hideMobile: true,
      },
      {
        label: i18n.t("projects", "Projects"),
        slug: "#projetos",
        desktopPosition: "left",
      },
      {
        label: i18n.t("contact", "Contact"),
        onClick: () => {
          setContactFormOpen(true);
          setMobileMenuOpen(null);
        },
        desktopPosition: "left",
      },
      {
        label: i18n.t("simulateSavings", "Simulate Savings"),
        onClick: () => {
          setSavingsCalculatorOpen(true);
          setMobileMenuOpen(null);
        },
        desktopPosition: "left",
      },
      !isAuthenticated && {
        label: i18n.t("saveMoney", "Save Money"),
        desktopPosition: "right",
        slug: "register",
        pill: true,
      },
      !isAuthenticated && {
        label: i18n.t("logIn", "Login"),
        slug: "login",
        desktopPosition: "right",
        // hideMobile: true,
        // subLinks: [
        //   {
        //     label: i18n.t("clientArea", "Client Area"),
        //     slug: "login",
        //     iconClass: "far fa-user",
        //   },
        //   {
        //     label: i18n.t("partnerArea", "Partner Area"),
        //     slug: "login",
        //     iconClass: "far fa-handshake-simple",
        //   },
        // ],
      },
    ];
  };

  // Render sub links similar to the original renderSubLinks method
  const renderSubLinks = () => {
    if (!subLinks) return null;
    return (
      <List>
        {subLinks.map((link) => (
          <ListItem
            key={`header-item-${subLinks.slug}`}
            button
            smooth
            component={HashLink}
            to={`/${link.slug}`}
            style={{ color: theme.palette.primary.main }}
          >
            <ListItemAvatar
              style={{
                textAlign: "center",
                minWidth: "48px",
                marginRight: "8px",
              }}
            >
              <i style={{ fontSize: "1.1rem" }} className={link.iconClass} />
            </ListItemAvatar>
            <ListItemText primary={<b>{link.label}</b>} />
          </ListItem>
        ))}
      </List>
    );
  };

  // Similar adaptations for renderDesktopLinks and renderMobileLinks
  const renderDesktopLinks = () => {
    // Adapt the original method to a functional component approach
    const curPath = location.pathname;
    return (
      <>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ margin: ".5rem 0" }}
        >
          <Grid item xl={9} lg={10} md={12} xs={12}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <Grid item>
                <Link to="/" className="no-select">
                  <img
                    style={{
                      marginRight: "1rem",
                      marginTop: "-6px",
                      verticalAlign: "middle",
                    }}
                    width={constants.headerLogoWidth}
                    src={constants.headerLogoUrl}
                    alt="Logo"
                  />
                </Link>
                {getLinks()
                  .filter((el) => el && el?.desktopPosition === "left")
                  .map((link, i) => {
                    let component;
                    let onClick;
                    if (link.onClick && !link.slug) {
                      component = Button;
                      onClick = link.onClick;
                    } else if (link.subLinks) {
                      component = Button;
                      onClick = handleOpenSubLinks(link.subLinks);
                    } else if (link.slug) {
                      const isHash = link.slug.startsWith("#");
                      console.log("Link", link.slug, isHash);
                      component = isHash ? HashLink : Link;
                      onClick = undefined;
                    }
                    return (
                      <Button
                        key={`menu-item-2-${i}`}
                        className={classnames(
                          link.pill ? classes.navBtn : classes.desktopNavBtn
                          // curPath === link.path && !link.pill ? classes.selected : null
                        )}
                        onClick={onClick}
                        variant={link.pill ? "contained" : "text"}
                        color={link.pill ? "secondary" : "default"}
                        component={component}
                        smooth
                        // size="large"
                        to={
                          !link.subLinks && !link.onClick
                            ? `/${link.slug}`
                            : undefined
                        }
                        style={{
                          marginRight: link.subLinks ? "1rem" : 0,
                          fontWeight: link.pill ? "bold" : "",
                        }}
                      >
                        <span>
                          {link.label}
                          {link.subLinks ? (
                            <ArrowDropDown
                              style={{ position: "absolute", right: "2px" }}
                            />
                          ) : null}
                        </span>
                      </Button>
                    );
                  })}
              </Grid>
              <Grid item>
                {getLinks()
                  .filter((el) => el && el?.desktopPosition === "right")
                  .map((link) => {
                    let component;
                    let onClick;
                    if (link.onClick && !link.slug) {
                      component = Button;
                      onClick = link.onClick;
                    } else if (link.subLinks) {
                      component = Button;
                      onClick = handleOpenSubLinks(link.subLinks);
                    } else if (link.slug) {
                      const isHash = link.slug.startsWith("#");
                      console.log("Link", link.slug, isHash);
                      component = isHash ? HashLink : Link;
                      onClick = undefined;
                    }
                    return (
                      <Button
                        key={`menu-item-1-${link.slug}`}
                        className={classnames(
                          link.pill ? classes.navBtn : classes.desktopNavBtn
                        )}
                        onClick={onClick}
                        variant={link.pill ? "contained" : "text"}
                        color={link.pill ? "secondary" : "default"}
                        smooth
                        component={component}
                        to={!link.subLinks ? `/${link.slug}` : undefined}
                        style={{
                          paddingRight: link.subLinks ? "2rem" : null,
                          fontWeight: link.pill ? "bold" : "",
                        }}
                      >
                        <span>
                          {link.label}
                          {link.subLinks ? (
                            <ArrowDropDown
                              style={{ position: "absolute", right: "0" }}
                            />
                          ) : null}
                        </span>
                      </Button>
                    );
                  })}
                {isAuthenticated ? <AccountMenuBtn /> : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Popover
          onClose={() => setSubLinksAnchorEl(null)}
          anchorEl={subLinksAnchorEl}
          open={!!subLinksAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          style={{ marginTop: "1rem" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {renderSubLinks()}
        </Popover>
      </>
    );
  };

  const renderMobileLinks = () => {
    // Adapt the original method to a functional component approach
    const curPath = location.pathname;
    return (
      <Grid
        container
        style={{ width: "100%", padding: "1rem 0" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Link to="/" className="no-select">
            <img
              style={{
                marginRight: "1.5rem",
                verticalAlign: "middle",
              }}
              width={constants.headerLogoWidth}
              src={constants.headerLogoUrl}
              alt="Logo"
            />
          </Link>
        </Grid>
        <Grid item>
          <IconButton
            onClick={(event) => setMobileMenuOpen(true)}
            color="inherit"
            aria-label="menu"
          >
            <Menu />
          </IconButton>
        </Grid>
        <Dialog
          onClose={() => setMobileMenuOpen(false)}
          open={!!mobileMenuOpen}
          fullScreen
          TransitionComponent={Transition}
        >
          <IconButton
            size="large"
            style={{
              color: "#fff",
              position: "absolute",
              margin: "16px 24px",
              top: 0,
              right: 0,
            }}
            onClick={() => setMobileMenuOpen(false)}
          >
            <Close />
          </IconButton>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ height: "100%", background: theme.palette.primary.main }}
          >
            {getLinks()
              .filter((el) => el && !el?.hideMobile)
              .map((link, i) => {
                let component;
                let onClick;
                if (link?.onClick) {
                  component = Button;
                  onClick = link?.onClick;
                } else if (link.subLinks) {
                  component = Button;
                  onClick = handleOpenSubLinks(link.subLinks);
                } else {
                  component = HashLink;
                  onClick = () => setMobileMenuOpen(false);
                }
                if (fullScreen && link.subLinks) {
                  return link.subLinks.map((subLink) => {
                    return (
                      <Grid item key={`menu-item-link-3-${i}`}>
                        <Button
                          variant="text"
                          size="large"
                          className={classnames(
                            curPath === `/${subLink.slug}`
                              ? classes.mobileSelected
                              : null
                          )}
                          component={Link}
                          onClick={() => setMobileMenuOpen(false)}
                          smooth
                          to={subLink.slug}
                        >
                          <b style={{ color: "#fff", fontSize: "24px" }}>
                            {subLink.label}
                          </b>
                        </Button>
                      </Grid>
                    );
                  });
                }

                return (
                  <Grid item key={`menu-item-link-3-${i}`}>
                    <Button
                      variant="text"
                      size="large"
                      className={classnames(
                        curPath === `/${link.slug}`
                          ? classes.mobileSelected
                          : null
                      )}
                      component={component}
                      onClick={onClick}
                      smooth
                      to={link.slug ? `/${link.slug}` : null}
                    >
                      <b style={{ color: "#fff", fontSize: "24px" }}>
                        {link.label}
                      </b>
                    </Button>
                  </Grid>
                );
              })}
            <Grid item style={{ padding: "36px 0 24px" }}>
              <Typography variant="h4">
                <Grid container spacing={4}>
                  <Grid item>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Facebook Profile"
                      href={constants.facebookUrl}
                    >
                      <i
                        className={classnames([
                          "fab fa-facebook",
                          classes.socialLinks,
                        ])}
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Instagram Profile"
                      href={constants.instagramUrl}
                    >
                      <i
                        className={classnames([
                          "fab fa-instagram",
                          classes.socialLinks,
                        ])}
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Linked In Profile"
                      href={constants.linkedInUrl}
                    >
                      <i
                        className={classnames([
                          "fab fa-linkedin",
                          classes.socialLinks,
                        ])}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                // onClick={() => setRegisterOpen(true)}
                to="/register"
                component={Link}
                style={{ padding: "12px 34px" }}
              >
                <b style={{ color: "#fff", fontSize: "16px" }}>
                  Quero economizar
                </b>
              </Button>
            </Grid>
            {/* <Grid item>
              <Typography
                variant="body1"
                onClick={() => {
                  alert("Coming Soon Partner Page");
                }}
                className={classnames([classes.greenLink])}
              >
                <i
                  className={classnames(["far", "fa-handshake-simple"])}
                  style={{ marginRight: ".5rem" }}
                />
                Seja nosso parceiro
              </Typography>
            </Grid> */}
          </Grid>
        </Dialog>
      </Grid>
    );
  };
  const appBarClasses = scrollTop
    ? [classes.scrollTop]
    : [classes.notScrollTop];
  if (opaque) appBarClasses.push(classes.opaque);
  return (
    <>
      <AppBar
        // position="absolute"
        className={classnames(appBarClasses)}
        elevation={0}
        style={{ backgroundColor: "rgba(255,255,255,0)" }}
      >
        <Toolbar>
          {fullScreen ? renderMobileLinks() : renderDesktopLinks()}
        </Toolbar>
      </AppBar>
      {data &&
        !data.loading &&
        !data.error &&
        data.me?.brContact &&
        data.me.brContact?.pendingTermsOfAdhesion
          ?.pendingSignatureDownloadUrl && (
          <TermsOfAdhesionSigningDialog
            open={true}
            pdfUrl={
              data.me.brContact.pendingTermsOfAdhesion
                .pendingSignatureDownloadUrl
            }
            termsOfAdhesionId={data.me.brContact.pendingTermsOfAdhesion.id}
          />
        )}
    </>
  );
};

export default graphql(currentUserQuery)(withTranslator(Header));
