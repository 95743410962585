import React, { Component } from "react";
import XLSX from "xlsx";
import { Button, Grid, IconButton, Input, Typography } from "@material-ui/core";
import { Close, Publish } from "@material-ui/icons";

/* generate an array of column objects */
const makeCols = (sRef) => {
  const o = [];
  const C = XLSX.utils.decode_range(sRef).e.c + 1;
  for (let i = 0; i < C; ++i)
    o[parseInt(i, 10)] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map((x) => {
    return `.${x}`;
  })
  .join(",");

class ExcelReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  handleClear() {
    const element = document.getElementById("file-input-form");
    if (element && element.reset) {
      element.reset();
    }
    this.setState({ file: {} });
  }

  handleChange(e) {
    const { files } = e.target;
    if (files && files[0])
      this.setState({
        file: files[0],
      });
  }

  handleFile() {
    const { handleData } = this.props;
    const { file } = this.state;
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        cellDates: true,
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[String(wsname)];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { dateNF: "YYYY-MM-DD" });
      /* Update state */
      handleData(data, makeCols(ws["!ref"]));
      // this.setState({ data: data, cols: makeCols(ws['!ref']) }, () => {
      //   console.log(JSON.stringify(this.state.data, null, 2));
      // });
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }

  render() {
    const { file } = this.state;
    const { type } = this.props;
    let fileNameCheck = type || "";
    if (type === "Projection") {
      fileNameCheck = "Forecast";
    }
    const noFile = !file || Object.keys(file).length === 0;
    const invalidFile =
      (type && !file) || !file.name || !file.name.includes(fileNameCheck);
    return (
      <>
        <Grid xs={12} item style={{ padding: "1em" }}>
          <form id="file-input-form">
            <Input
              type="file"
              className="form-control"
              id="file"
              accept={SheetJSFT}
              onChange={this.handleChange}
            />
            <IconButton onClick={this.handleClear}>
              <Close />
            </IconButton>
          </form>
        </Grid>
        <Grid xs={12} item style={{ padding: "1em" }}>
          <Button
            disabled={invalidFile}
            color="primary"
            variant="contained"
            value="Process File"
            onClick={this.handleFile}
          >
            <Publish />
            Processar arquivo
          </Button>
          {!noFile && invalidFile ? (
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", color: "red" }}
            >
              <em>
                Algo não parece certo. Você tem certeza de que esse é o arquivo
                correto?
              </em>
            </Typography>
          ) : null}
        </Grid>
      </>
    );
  }
}

export default ExcelReader;
