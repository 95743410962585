import React, { Component, Fragment } from "react";

import { Grid, Hidden, Typography, withMobileDialog } from "@material-ui/core";
import { LooksOne, LooksTwo, Looks3, Looks4 } from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";
import withTranslator from "./hocs/withTranslator";

const styles = (theme) => ({
  iconSize: { fontSize: "2rem" },
});

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactFormOpen: false, // eslint-disable-line
    };
  }

  getVantagans() {
    return [
      {
        copy: "Você paga sua conta com desconto mensal na tarifa de energia.",
        iconClass: "far fa-badge-percent",
      },
      {
        copy: "Sem visita técnica ou de instalação. Processo 100% digital.",
        iconClass: "far fa-wrench",
      },
      {
        copy: "Você não paga nada pela implantação e operação da usina solar.",
        iconClass: "far fa-solar-panel",
      },
      {
        copy: "Não cobramos fidelidade e não temos taxas escondidas.",
        iconClass: "far fa-money-check-dollar",
      },
      {
        copy: "Você colabora com um planeta melhor,utilizando energia limpa e renovável.",
        iconClass: "far fa-earth-americas",
      },
      {
        copy: "Consumir energia solar agrega valor sustentável para a sua vida, empresa e marca.",
        iconClass: "far fa-plug",
      },
      {
        copy: "Você movimenta a economia, ajudando na geração de empregos e oportunidades.",
        iconClass: "far fa-hand-holding-dollar",
      },
      {
        copy: "Caso acumule créditos, eles poderão ser utilizados no prazo de até 5 anos.",
        iconClass: "far fa-sack-dollar",
      },
    ];
  }

  getSteps() {
    return [
      "Você cadastra, em nosso website, a sua conta de energia mais recente da sua empresa ou residência. É fácil rápido e gratuito.",
      "Faremos a análise do seu consumo de energia. Com o cadastro aprovado, você receberá o contrato de adesão por e-mail para assinar eletronicamente. Tudo simples, digital e sem burocracia.",
      "Você agora participa da Usina Solar Energea e receberá os créditos gerados na usina, diretamente em sua conta de energia.",
      "Sua conta de energia virá mais barata devido aos créditos recebidos. Você pagará pelos créditos com desconto todo mês, em um boleto enviado pelo Consórcio Energea.",
    ];
  }

  render() {
    const { theme, fullScreen } = this.props;
    return (
      <>
        <Grid
          container
          style={{ width: "100%", padding: "1rem" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs="auto">
            <Typography variant="body1" color="primary">
              {" "}
              <i
                style={{ fontSize: "1.25rem" }}
                className="fa-regular fa-dollar-sign"
              ></i>
            </Typography>
          </Grid>
          <Grid item xs={fullScreen}>
            <Typography
              variant="body2"
              style={{ padding: "0 1rem", color: theme.palette.blue3.main }}
            >
              Desconto válido para a região de MG. Para a região do RJ, o
              desconto é de até 12%.
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="body1" color="primary">
              <i
                style={{ fontSize: "1.25rem" }}
                className="fa-regular fa-dollar-sign"
              ></i>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          id="como-funciona"
          container
          justifyContent="center"
          alignContent="center"
          style={{
            position: "relative",
            backgroundColor: "#fff",
            backgroundRepeat: "no-repeat",
            padding: fullScreen ? "4rem 0 0" : "4rem 0",
            backgroundPosition: "right bottom",
            width: "100%",
            backgroundImage: fullScreen
              ? null
              : "url(https://res.cloudinary.com/dwelloptimal/image/upload/v1653003659/energea/br-images/Group_157.png)",
          }}
        >
          {/* TODO: uncomment when video is ready */}
          {/* <Hidden smDown>
            <img
              onClick={() => alert("this will play a video when ready")}
              style={{
                cursor: "pointer",
                right: 200,
                top: "44%",
                position: "absolute",
                zIndex: 10,
              }}
              src="https://res.cloudinary.com/dwelloptimal/image/upload/v1654636165/energea/br-images/play.png"
            />
          </Hidden> */}
          <Grid
            item
            xl={8}
            lg={9}
            // md={10}
            xs={12}
            style={{ paddingRight: "1rem", paddingLeft: "1rem" }}
          >
            <Typography
              color="primary"
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
              variant="h2"
              gutterBottom
            >
              Como funciona?
            </Typography>
            <Grid
              container
              style={{
                width: "100%",
                paddingBottom: !fullScreen ? "4rem" : null,
              }}
              spacing={4}
            >
              {this.getSteps().map((step, index) => (
                <Grid
                  container
                  key={`how-it-works-step-${index}`}
                  item
                  style={{ width: "100%" }}
                  spacing={4}
                  alignItems="center"
                >
                  <Grid item style={{ width: "138px" }}>
                    <Typography
                      style={{
                        fontVariantNumeric: "tabular-nums",
                        // width: "81px",
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "64px",
                        lineHeight: "78px",
                        textAlign: "center",
                        color: theme.palette.green1.main,
                      }}
                    >{`0${index + 1}`}</Typography>
                  </Grid>
                  <Grid item md={6} xs>
                    <Typography
                      variant="body1"
                      style={{ color: theme.palette.blue3.main }}
                    >
                      {step}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {/* TODO: uncomment when video is ready */}
        {/* <Hidden mdUp>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "-6px",
              // marginTop: "-4rem",
              position: "relative",
              background: "#fff",
            }}
          >
            <img
              style={{ width: "100%" }}
              src="https://res.cloudinary.com/dwelloptimal/image/upload/v1653003659/energea/br-images/Group_157.png"
            />
            <img
              onClick={() => alert("this will play a video when ready")}
              style={{
                cursor: "pointer",
                right: "50%",
                top: "50%",
                position: "absolute",
                zIndex: 10,
                transform: "translate(79px, -60px)",
              }}
              src="https://res.cloudinary.com/dwelloptimal/image/upload/v1654636165/energea/br-images/play.png"
            />
          </Grid>
        </Hidden> */}
        <Grid
          container
          justifyContent="center"
          style={{
            background: "linear-gradient(256.7deg, #8CC63F 0%, #007F83 96.14%)",
            width: "100%",
            minHeight: "20rem",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <img
            style={{
              width: "100%",
              opacity: 0.25,
              position: "absolute",
              left: 0,
              bottom: 0,
              width: fullScreen ? null : "100%",
              height: "auto",
              zIndex: 1,
            }}
            src="https://res.cloudinary.com/dwelloptimal/image/upload/v1653005598/energea/br-images/Group.png"
          />
          <Grid item xl={8} lg={9} md={10} xs={12} style={{ zIndex: 10 }}>
            <Typography
              style={{
                marginTop: "5rem",
                marginBottom: "5rem",
                width: "100%",
                textAlign: "center",
                color: "#fff",
              }}
              variant="h2"
              gutterBottom
            >
              Vantagens
            </Typography>
            {/* TODO: HERES WHERE WE LEFT OFF */}
            <Grid
              container
              style={{
                width: "100%",
                marginBottom: fullScreen ? "6rem" : "12rem",
              }}
            >
              {this.getVantagans().map((el, i) => {
                return (
                  <Grid
                    key={`advantages-${i}`}
                    item
                    lg={3}
                    md={4}
                    xs={6}
                    container
                    direction="column"
                    style={{ color: "#fff", padding: "1.5rem 1rem" }}
                  >
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                      style={{
                        boxSizing: "border-box",
                        width: "54px",
                        height: "54px",
                        left: "135px",
                        top: "175px",
                        border: "1px solid rgba(255, 255, 255, 0.25)",
                        borderRadius: "8px",
                        marginBottom: ".5rem",
                      }}
                    >
                      <Grid item>
                        <i
                          style={{ fontSize: "1.8rem", marginTop: "5px" }}
                          className={el.iconClass}
                        ></i>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">{el.copy}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(withTranslator(HowItWorks))
);
