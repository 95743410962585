import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";
import { Image } from "cloudinary-react";

// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import {
  Button,
  // CircularProgress,
  Grid,
  // Grow,
  // Hidden,
  IconButton,
  Typography,
  useMediaQuery,
  withMobileDialog,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";
import BackgroundSlider from "react-background-slider";
import { loader } from "graphql.macro";

// import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';
import Clients from "./Clients";
import Projects from "./Projects";
// import Contact from "./Contact";
import Faq from "./Faq";
// import Benefits from "./Benefits";
import HowItWorks from "./HowItWorks";
import WhoWeAre from "./WhoWeAre";
// import SavingsCalculator from "./SavingsCalculator";
import { constants } from "../global";

const homeQuery = loader("../queries/Home.graphql");
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const mobilePadding = "1rem";
const styles = (theme) => ({});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      contactFormOpen: false, // eslint-disable-line
    };
  }

  renderAboveTheFold() {
    const { fullScreen, setSavingsCalculatorOpen } = this.props;
    return (
      <Grid
        container
        justifyContent="center"
        style={{
          height: "100vh",
          minHeight: "100vh",
          maxHeight: "100vh",
          overflow: "hidden",
          position: "relative",
          background:
            "linear-gradient(251.89deg, rgba(27, 44, 74, 0) 6.99%, #1B2C4A 98.57%)",
        }}
      >
        <BackgroundSlider
          images={[
            "https://res.cloudinary.com/dwelloptimal/image/upload/v1652988660/energea/br-images/banner-3.png",
            "https://res.cloudinary.com/dwelloptimal/image/upload/v1652988659/energea/br-images/banner-2.jpg",
            "https://res.cloudinary.com/dwelloptimal/image/upload/v1652988658/energea/br-images/banner-1.jpg",
            "https://res.cloudinary.com/dwelloptimal/image/upload/v1652988657/energea/br-images/banner-4.jpg",
          ]}
          duration={5}
          transition={1}
        />
        <Grid
          item
          style={{
            top: "20%",
            width: "100%",
            height: "80vh",
            position: "absolute",
          }}
        >
          <Grid container justifyContent="center" style={{ height: "100%" }}>
            <Grid
              item
              container
              xs={12}
              md={10}
              lg={9}
              xl={8}
              style={{
                position: "absolute",
                width: "100%",
                marginBottom: "1rem",
                height: "100%",
                padding: mobilePadding,
              }}
              alignItems={fullScreen ? "center" : "space-between"}
            >
              <Grid item lg={7} md={9} xs={12}>
                <Typography
                  variant="h1"
                  style={{
                    color: "#fff",
                    zIndex: "10000",
                    textShadow: "rgba(0,0,0,1) 0 0 12px",
                  }}
                  gutterBottom
                >
                  Descubra o jeito novo de ter sua conta de luz até{" "}
                  {constants.discountRate * 100}% mais barata TODO MÊS.
                </Typography>
                <Typography
                  style={{
                    color: "#fff",
                    zIndex: "10000",
                    textShadow: "rgba(0,0,0,1) 0 0 12px",
                  }}
                  variant="h3"
                  gutterBottom
                >
                  Consumir energia solar é mais barato e agrega valor
                  sustentável à sua marca e ao seu estilo de vida.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems={!fullScreen ? "center" : "flex-start"}
                  direction={fullScreen ? "column" : "row"}
                  style={{ width: "100%", height: fullScreen ? "100%" : null }}
                >
                  <Grid
                    item
                    style={{ marginBottom: fullScreen ? "1rem" : null }}
                  >
                    <Button
                      size="large"
                      color="secondary"
                      onClick={() => setSavingsCalculatorOpen(true)}
                      variant="contained"
                    >
                      Simular economia
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ color: "#fff" }}>
                      <Grid
                        container
                        alignItems="center"
                        direction={fullScreen ? "row-reverse" : "row"}
                      >
                        <Grid item>
                          <b>
                            Como contribuir para um {fullScreen ? <br /> : null}
                            planeta mais sustentável{" "}
                          </b>
                        </Grid>
                        <Grid item>
                          <IconButton
                            size="small"
                            component={HashLink}
                            smooth
                            to="/#como-funciona"
                            aria-label="go to next"
                            color="primary"
                            variant="contained"
                            style={{
                              border: "4px solid rgba(255,255,255,.5)",
                              padding: 0,
                              margin: "0 .75rem",
                            }}
                          >
                            <KeyboardArrowDown
                              style={{
                                background: "#fff",
                                borderRadius: "50px",
                                padding: "2px",
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { loading } = this.state;
    const { theme, fullScreen, data } = this.props;
    return (
      <Fragment>
        {this.renderAboveTheFold()}
        <HowItWorks />
        <WhoWeAre />
        <Grid
          container
          // style={{ height: "100vh" }}
          alignItems="stretch"
          direction="row"
        >
          <Projects dark />
        </Grid>
        <Clients />
        {/* TODO: Uncomment and make the blog */}
        {/* <Grid
          container
          style={{
            padding: "1rem",
            position: "relative",
            background: "#fff",
            // background: theme.palette.primary.main,
          }}
          justifyContent="center"
        >
          <Grid item xl={8} lg={9} xs={12}>
            <Typography
              style={{ marginTop: "2rem", width: "100%" }}
              gutterBottom
              color="primary"
              variant="h2"
            >
              Fique por dentro
            </Typography>
          </Grid>
          <Grid
            style={{ position: "relative" }}
            item
            xl={8}
            lg={9}
            md={10}
            xs={12}
          >
            {loading ? (
              <Grid
                container
                alignItems="center"
                style={{ position: "relative" }}
              >
                <Grid
                  style={{
                    width: "100%",
                    position: "absolute",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <CircularProgress style={{ color: "#fff" }} />
                </Grid>
              </Grid>
            ) : null}
            <iframe
              title="Energea Instagram Feed"
              src="https://www.powr.io/social-feed/u/9d841a9c_1597964052#platform=iframe"
              style={{ width: "100%" }}
              height="420px"
              onLoad={() => this.setState({ loading: false })}
              frameBorder="0"
            ></iframe>
          </Grid>
        </Grid> */}
        <Faq />
      </Fragment>
    );
  }
}

export default graphql(homeQuery)(
  withStyles(styles, { withTheme: true })(withMobileDialog()(Home))
);
