// cache-2
import React, { useRef, useState, useEffect } from "react";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import SignatureCanvas from "react-signature-canvas";
import {
  Button,
  Collapse,
  Dialog,
  Grid,
  IconButton,
  Typography,
  withMobileDialog,
  withStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

import withTranslator from "./hocs/withTranslator";
import withSnackbar from "./hocs/withSnackbar";
import "./PdfViewerWithSignature.css";

const signTermsOfAdhesionMutation = loader(
  "../mutations/SignTermsOfAdhesion.graphql"
);

const styles = (theme) => ({
  alertMessage: {
    "& .MuiAlert-message": {
      width: "100%",
    },
  },
});

const PdfViewerWithSignature = (props) => {
  const { pdfUrl, open, fullScreen, i18n, classes } = props;

  const signatureCanvas = useRef(null);

  const [page, setPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(!!open);
  const [signature, setSignature] = useState(null);
  const [signingDialogOpen, setSigningDialogOpen] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(500);
  const [canvasHeight, setCanvasHeight] = useState(200);

  const signature2PageNumber = totalPageCount ? totalPageCount - 2 : 8;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [], // removes side toolbar
    renderToolbar: (toolbarSlot) => {
      // removes top toolbar
      return [];
    },
  });

  const updateCanvasSize = () => {
    const width = Math.min(window.innerWidth * 0.9, 500);
    const height = Math.min(window.innerHeight * 0.3, 200);
    setCanvasWidth(width);
    setCanvasHeight(height);
  };

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener("resize", updateCanvasSize);
    return () => window.removeEventListener("resize", updateCanvasSize);
  }, []);

  const clearSignature = () => {
    signatureCanvas.current.clear();
    setSignature(null);
  };

  const saveSignature = () => {
    // TODO: Check size/length before submitting with error message. Graphql may have a limit and pdfmake does have a limit
    if (signatureCanvas.current.isEmpty()) {
      setSignature(null);
      return;
    }
    const trimmedCanvas = signatureCanvas.current.getTrimmedCanvas();
    const signatureDataUrl = trimmedCanvas.toDataURL("image/png");
    setSignature(signatureDataUrl);
  };

  const submitSignedDocument = () => {
    if (!signature) {
      alert("Por favor, assine todos os campos");
      return;
    }

    const { signTermsOfAdhesion, termsOfAdhesionId, snackbar } = props;
    signTermsOfAdhesion({
      variables: {
        input: {
          brTermsOfAdhesionId: termsOfAdhesionId,
          signature,
        },
      },
    }).then((res) => {
      setDialogOpen(false);
      snackbar.setState({
        snackbarMessage: `Documento assinado com sucesso`,
        snackbarOpen: true,
        snackbarVariant: "success",
      });
    });
  };

  const handlePageChange = (page) => {
    const pageNumber = page.currentPage + 1;
    setPage(pageNumber);
    if (!totalPageCount) {
      setTotalPageCount(page.doc.numPages);
    }
  };

  const renderSigningDialog = () => {
    if (!signingDialogOpen) return null;
    const title = `Assine abaixo:`;
    // NOTE: the below would be nice, but canvas.current isn't defined until its been clicked on
    // const canvasIsEmpty = canvas?.current?.isEmpty();
    return (
      <Dialog open={!!signingDialogOpen} fullScreen={fullScreen}>
        <Grid
          container
          alignItems="center"
          direction="column"
          style={{ padding: "1rem" }}
        >
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setSigningDialogOpen(null)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <SignatureCanvas
              ref={signatureCanvas}
              penColor="black"
              canvasProps={{
                width: canvasWidth,
                height: canvasHeight,
                className: "sigCanvas",
              }}
            />
          </Grid>
          <Grid container item alignItems="center" justifyContent="center">
            <Grid item>
              <Button onClick={() => clearSignature()}>
                {i18n.t("reset", "Reset")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  saveSignature();
                  setSigningDialogOpen(null);
                }}
                variant="contained"
                color="secondary"
                // disabled={canvasIsEmpty}
              >
                {i18n.t("continue", "Continue")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  };

  const formCompleted = !!signature;
  return (
    <Dialog open={!!dialogOpen} style={{ overflow: "scroll" }} fullScreen>
      <Grid container style={{ padding: "1rem" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: "0 20px" }}
        >
          <Grid item>
            <Typography variant="h6">
              {fullScreen
                ? "Assine seus termos"
                : "Assine seus termos de adesão"}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                if (formCompleted) {
                  submitSignedDocument();
                } else {
                  setDialogOpen(false);
                }
              }}
              variant={formCompleted ? "contained" : null}
              color={formCompleted ? "primary" : "default"}
            >
              {formCompleted
                ? i18n.t("submitDocument", "Submit document")
                : i18n.t("signLater", "Sign later")}
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ padding: "0 20px" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} md={8} style={{ minHeight: "4rem" }}>
            {/* {[2, 3, 5, signature2PageNumber].indexOf(page) > -1 && ( */}
            {/* <Collapse in={[2, 3, 5, signature2PageNumber].indexOf(page) > -1}> */}
            <Alert
              severity={signature ? "success" : "warning"}
              icon={false}
              className={classes.alertMessage}
            >
              <Grid
                container
                style={{ width: "100%" }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  {signature
                    ? i18n.t("signed", "Signed")
                    : i18n.t(
                        "yourSignatureIsRequired",
                        "Your signature is required"
                      )}
                </Grid>
                {!signature && (
                  <Grid item>
                    <Button
                      onClick={() => {
                        setSigningDialogOpen(true);
                      }}
                      variant="contained"
                      color="secondary"
                      size="small"
                    >
                      {i18n.t("signAllPages", "Sign all pages")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Alert>
            {/* </Collapse> */}
            {/* )} */}
          </Grid>
          {renderSigningDialog()}
          <Grid xs={12} md={8}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
            >
              <Grid
                item
                style={{
                  width: "100%",
                  height: "600px",
                  border: "1px solid #ccc",
                  marginBottom: "20px",
                }}
              >
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[defaultLayoutPluginInstance]}
                  onPageChange={(page) => {
                    handlePageChange(page);
                  }}
                />
              </Grid>
            </Worker>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(
  withSnackbar(
    withMobileDialog()(
      graphql(signTermsOfAdhesionMutation, {
        name: "signTermsOfAdhesion",
      })(withTranslator(PdfViewerWithSignature))
    )
  )
);
