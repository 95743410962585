import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const withTranslator = (WrappedComponent, libs) => {
  const HOC = (props) => {
    const { t, i18n } = useTranslation(libs || []);
    return (
      <Fragment>
        <WrappedComponent t={t} i18n={i18n} {...props} />
      </Fragment>
    );
  };
  return HOC;
};

export default withTranslator;
