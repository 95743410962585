import React, { useState, useEffect } from "react";
import isEmail from "validator/es/lib/isEmail";
import classNames from "classnames";
import { Link, Navigate } from "react-router-dom";
import cloudinary from "cloudinary-core";
import { useOktaAuth } from "@okta/okta-react";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Grid,
  Typography,
  useTheme,
  withMobileDialog,
} from "@material-ui/core";
import {
  CheckCircle,
  EmailOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import { loader } from "graphql.macro";
import { graphql } from "react-apollo";

import withSnackbar from "./hocs/withSnackbar";
import withTranslator from "./hocs/withTranslator";

const logToSlackMutation = loader("../mutations/LogToSlack.graphql");

const styles = (theme) => ({
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  submitBtn: {
    marginTop: "1rem",
    marginBottom: "1rem",
    fontSize: "1.1rem",
    paddingTop: "13px",
    paddingBottom: "13px",
  },
  aTagBtn: {
    cursor: "pointer",
  },
});

const cl = new cloudinary.Cloudinary({
  cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  secure: true,
});

const Login = (props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [sessionToken, setSessionToken] = useState();
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotPasswordErrors, setForgotPasswordErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginErrors, setLoginErrors] = useState([]);
  const [hidePassword, setHidePassword] = useState(true);
  const theme = useTheme(); // Access the theme object here
  const [authenticated, setAuthenticated] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const { classes, fullScreen } = props;
  const incorrectPassword =
    loginErrors?.[0] === "Incorrect email/password" ||
    loginErrors?.[0] === "Authentication failed";
  const { i18n } = props;

  useEffect(() => {
    if (authState?.isPending) {
      return;
    }

    if (authState?.isAuthenticated) {
      // User is authenticated
      // You could do something with the user info at this point
    } else {
      // User is not authenticated
      // You could possibly redirect them to a login page
    }
  }, [authState, oktaAuth]); // Dependencies for useEffect

  const loginRedirect = (st) => {
    if (oktaAuth.getOriginalUri() === window.origin) {
      oktaAuth.setOriginalUri("/dashboard");
    }
    oktaAuth.signInWithRedirect({
      sessionToken: st,
    });
  };

  const sendResetLink = () => {
    const { snackbar, logToSlack } = props;
    oktaAuth
      .forgotPassword({
        username: forgotPasswordEmail,
        // TODO: setup EMAIL as well
        factorType: "EMAIL",
      })
      .then(
        () => {
          snackbar.setState({
            snackbarMessage: `${i18n.t(
              "emailSuccessfullySentTo",
              "Email successfully sent to"
            )} ${forgotPasswordEmail}.`,
            snackbarOpen: true,
            snackbarVariant: "success",
            autoHideDuration: 6000,
          });
          setForgotPasswordEmail("");
          setForgotPasswordErrors([]);
          setEmailSent(true);
        },
        (e) => {
          snackbar.setState({
            snackbarMessage: i18n.t(
              "authError",
              "Our authentication server is currently experiencing some issues which may be affecting your ability to log in. Please try again later. Apologies for the inconvenience!"
            ),
            snackbarOpen: true,
            snackbarVariant: "error",
          });
          logToSlack({
            variables: {
              input: {
                title: "Error Sending Forgot Password Link (energea.com.br)",
                type: "platform-error",
                data: [
                  {
                    label: "Error",
                    value: String(e),
                  },
                  {
                    label: "Email",
                    value: forgotPasswordEmail,
                  },
                  {
                    label: "User Agent",
                    value: navigator?.userAgent,
                  },
                ],
              },
            },
          });
        }
      );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const transaction = await oktaAuth.signInWithCredentials({
        username,
        password,
      });
      // if (transaction.status === "SUCCESS") {
      loginRedirect(transaction.sessionToken);
      // } else {
      // }
    } catch (err) {
      setLoginErrors([err.message]);
      console.error("Failed to log in", err);
    }
  };
  if (authState?.isPending) return null; // Render nothing if authentication status is unknown
  if (authState?.isAuthenticated) return <Navigate to="/dashboard" />; // Redirect to dashboard if user is authenticated
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ background: "#fff", height: "100vh" }}
      >
        <Grid
          xs={12}
          item
          style={{ padding: 16, maxWidth: 500, textAlign: "center" }}
        >
          <img
            style={{
              width: "4rem",
              height: "4rem",
              marginTop: "80px",
              marginBottom: "1rem",
            }}
            src={cl.url("energea/energea-global-favicon", {
              width: "80",
              quality: "auto",
            })}
          />
          <form onSubmit={handleSubmit} className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <TextField
                variant="outlined"
                label={"Email"}
                required
                type="email"
                value={username}
                name="username"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailOutlined color="primary" />
                    </InputAdornment>
                  ),
                }}
                autoComplete="email"
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextField
                variant="outlined"
                label={i18n.t("password", "Password")}
                required
                name="password"
                type={hidePassword ? "password" : "text"}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginLeft: 0 }}>
                      <IconButton
                        color="primary"
                        style={{ marginRight: "-12px" }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setHidePassword(!hidePassword);
                        }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {hidePassword ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            {loginErrors.map((error) => (
              <Typography
                variant="body2"
                gutterBottom
                color="error"
                key={`login-error-${error}`}
              >
                {error === "Authentication failed"
                  ? "Username and/or password is incorrect"
                  : error}
              </Typography>
            ))}
            {loginErrors?.length > 0 && !incorrectPassword ? (
              <Alert severity="warning">
                {i18n.t(
                  "authError2",
                  `Our authentication server has been experiencing some issues
                which may be blocking your login. Please try again in 15
                minutes. Apologies for the inconvenience!`
                )}
              </Alert>
            ) : null}

            <Button
              className={classes.submitBtn}
              type="submit"
              fullWidth
              disabled={loading}
              size="large"
              variant="contained"
              color="primary"
            >
              {loading ? (
                <CircularProgress style={{ position: "absolute" }} size={30} />
              ) : null}
              {i18n.t("logIn", "Log in")}
            </Button>
          </form>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: ".25rem" }}
          >
            <Grid item>
              <Typography gutterBottom variant="body2">
                <a
                  className={classes.aTagBtn}
                  onClick={() => {
                    setForgotPasswordOpen(true);
                  }}
                  onKeyDown={(event) => event.preventDefault}
                  tabIndex={0}
                  role="button"
                >
                  {i18n.t("forgotPassword", "Forgot Password?")}
                </a>
              </Typography>
            </Grid>
            <Grid item>
              <Grid>
                <Typography variant="body2">
                  <Link className={classes.aTagBtn} to="/register">
                    {i18n.t("signUp", "Sign Up")}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={!!forgotPasswordOpen}
        onClose={() => {
          setForgotPasswordEmail("");
          setForgotPasswordErrors([]);
          setForgotPasswordOpen(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          {emailSent ? (
            <Grid container alignItems="center">
              {i18n.t("passwordResetEmailSent", "Password Reset Email Sent")}{" "}
              <CheckCircle
                style={{
                  color: theme.palette.green2.main,
                  marginLeft: ".5rem",
                }}
              />
            </Grid>
          ) : (
            i18n.t("resetPassword", "Reset password")
          )}
        </DialogTitle>
        <DialogContent>
          <Collapse in={!!emailSent}>
            <Grid
              container
              item
              justifyContent="center"
              style={{ marginBottom: "1rem" }}
            >
              <Icon
                color="secondary"
                style={{ fontSize: "6rem", width: "7.5rem" }}
                size="large"
                className={classNames("fa-regular", "fa-envelope-circle-check")}
              />
            </Grid>
          </Collapse>
          <DialogContentText>
            {!emailSent
              ? i18n.t(
                  "enterResetEmail",
                  "Enter the email address associated with your account, and we'll email you a link to reset your password."
                )
              : i18n.t(
                  "resetEmailSent",
                  "If an account is associated with that email address, an email will be sent containing a link to reset your password. For security reasons, this link will expire in 60 minutes. Please allow a few minutes for the email to arrive."
                )}
          </DialogContentText>
          <Collapse in={!emailSent}>
            <FormControl margin="dense" required fullWidth>
              <TextField
                variant="outlined"
                label="Email"
                type="email"
                value={forgotPasswordEmail}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailOutlined color="primary" />
                    </InputAdornment>
                  ),
                }}
                autoComplete="email"
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
              />
            </FormControl>
          </Collapse>
          {forgotPasswordErrors.map((e) => (
            <Typography gutterBottom color="error" key={e}>
              {e}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            style={{ width: "100%", padding: "1rem" }}
            justifyContent="center"
          >
            <Grid item>
              {emailSent ? (
                <Button
                  size="large"
                  onClick={() => setForgotPasswordOpen(false)}
                  variant="contained"
                  color="primary"
                >
                  {i18n.t("backToLogin", "Back to Login")}
                </Button>
              ) : (
                <>
                  <Button
                    size="large"
                    onClick={() => setForgotPasswordOpen(false)}
                    color="primary"
                  >
                    {i18n.t("backToLogin", "Back to Login")}
                  </Button>
                  <Button
                    size="large"
                    onClick={sendResetLink}
                    color="primary"
                    variant="contained"
                    disabled={
                      !forgotPasswordEmail || !isEmail(forgotPasswordEmail)
                    }
                  >
                    {i18n.t("sendResetLink", "Send Reset Link")}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withSnackbar(
  withStyles(styles, { withTheme: true })(
    graphql(logToSlackMutation, { name: "logToSlack" })(
      withMobileDialog()(withTranslator(Login))
    )
  )
);
